const date = /^(?<date>[0-9]{4}:[0-9]{2}:[0-9]{2})(?<time> [0-9]{2}:[0-9]{2}:[0-9]{2})?$/;
const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const escapeCharacters = /(&nbsp;|&lt;|&gt;|&amp;)/;
const gamsRegex = /^([a-zA-Z]{1}[0-9a-zA-Z]{1})-([a-zA-Z]{2}\d{1,3})/;
const hexCode = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const imRegex = /^(im\x2d)([0-9]{4,8})$/;
const imagePipelineRegex = /^20\d{6}-\d{6}-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-[a-zA-Z0-9]+$/;
const imageIMPipelineRegex = /^(im\x2d)(20\d{6}-\d{6}-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}-[a-zA-Z0-9]+)$/;
const imuRegex = /^(imu\x2d)([0-9]{1,17})$/;
const phisRegex = /^([a-zA-Z0-9]{32,32}$)|(^[0-9]{6,10}$)|(^imut?-[0-9]{16,18})$/;
const chartlosIdRegex = /^([a-zA-Z0-9]{24,24})$/;
const lineBreak = /(?:\r\n|\r|\n)/g;
const returnAndNewLine = /(\r\n|\r|\n)/;
const slug = /^[0-9a-zA-Z-_]{0,15}$/;
const slugReplace = /["~!@#$%^&*()+=`{}[\]|\\:;'<>,./?"_ \u00A0\t\r\n]+/g;
const smartCropVersion = /^\d+\.\d+\.\d+$/;
const url = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%.+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%+.~#?&//=]*)$/; // * http || https

const constantsUtil = {
  constantList: {
    ENV: 'IMENV$[value]',
    UPLOAD: '$[value]UPLOAD',
    AUTO_PUB: 'IMAUTOPUB',
    IM_ONETIME_USE: 'IMONETIMEUSE',
    IM_EXTERNAL: 'IMEXTERNAL$[value]',
    IM_BATCH: 'IMBATCH$[value]',
    DO_NOT_CROP: 'DONOTCROP',
    UNDATED: 'Undated',
    UNCREDITED: 'Uncredited',
    ONE_TIME_USE: '*** ONE-TIME USE ***',
    TOOL_ID: 'client',
    API_ID: 'api',
    MAX_BATCH_CROP_COUNT: 20,
  },

  regex: {
    date,
    email,
    escapeCharacters,
    gams: gamsRegex,
    hexCode,
    im: imRegex,
    imagePipeline: imagePipelineRegex,
    imageIMPipeline: imageIMPipelineRegex,
    imu: imuRegex,
    phis: phisRegex,
    chartlosId: chartlosIdRegex,
    lineBreak,
    returnAndNewLine,
    slug,
    slugReplace,
    smartCropVersion,
    url,
  },

  get(constant, value) {
    const data = this.constantList[constant];
    if (typeof data === 'string') {
      return data.replace('$[value]', value || '').toUpperCase();
    }
    return data;
  },

  removePrefixes(term) {
    let newSlug = '';
    if (term) {
      const splitSlug = term && term.split(' ');
      for (let i = 0; i <= splitSlug.length - 1; i++) {
        if (constantsUtil.regex.im.test(splitSlug[i]) || splitSlug[i].indexOf('im-') !== -1) {
          newSlug += `${splitSlug[i].replace('im-', '')} `;
        } else if (constantsUtil.regex.imu.test(splitSlug[i]) || splitSlug[i].indexOf('imu-') !== -1) {
          newSlug += `${splitSlug[i].replace('imu-', '')} `;
        } else if (constantsUtil.regex.imageIMPipeline.test(splitSlug[i]) || splitSlug[i].indexOf('im-') !== -1) {
          newSlug += `${splitSlug[i].replace('im-', '')} `;
        } else {
          newSlug += `${splitSlug[i]} `;
        }
      }
    }
    return newSlug.trim();
  },
};

export default constantsUtil;
