import { isSlugValid } from '../../routes/property/Crop/PublishTab/utils';
import { clearImages } from './utils';

export const actions = {
  SET_IMAGES: 'crop/set_images',
  SET_SLUG: 'crop/set_slug',
  SET_NOTES: 'crop/set_notes',
  SET_META_FORM: 'crop/set_meta_form',
  GO_BACK: 'crop/go_back',
  NEXT_PAGE: 'crop/next_page',
  SET_TAB: 'crop/set_tab',
  SET_CROP: 'crop/set_crop',
  SET_POSITION: 'crop/set_position',
  SET_IS_LOADING: 'crop/is_loading',
  SET_IS_RECROP: 'crop/is_recrop',
  SET_COLOR: 'crop/set_color',
  SET_COMPLETED: 'crop/set_completed',
  CLEAR_STATE: 'crop/clear_state',
  SET_SHOW_RECROP: 'crop/set_show_recrop',
  SET_RECROP: 'crop/set_recrop',
  SORT_IMAGES: 'crop/sort_images',
};

export const initialState = {
  completed: [],
  images: [],
  batchIndex: -1,
  slug: null,
  notes: null,
  tabIndex: 0,
  isRecrop: false,
  hasError: false,
  isLoading: false,
  showRecrop: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_RECROP: {
      if (!state.images.length) { return state; }
      const { crops = [], notes, slug, meta } = action.recrop;
      const existingCrops = {};
      const editingCrop = state.images[0].sizes.map((size) => size.name);

      crops.forEach(({ image, size, ...rest }) => {
        existingCrops[size || `custom_${rest.outputWidth}x${rest.outputHeight}`] = {
          url: '',
          crop: {
            ...rest,
            aspect: rest.outputWidth / rest.outputHeight,
          },
          isDone: !editingCrop.includes(size),
        };
      });

      const newImage = {
        ...state.images[state.batchIndex],
        cropSlug: slug,
        cropNotes: notes,
        cropHasError: false,
        crops: existingCrops,
        recropId: action.recropId,
      };

      if (meta?.color) {
        newImage.prominent = meta.color.prominent;
        newImage.background = meta.color.background;
      }

      return { ...state, images: [newImage] };
    }
    case actions.SET_IS_RECROP:
      return { ...state, isRecrop: action.isRecrop, showRecrop: false };
    case actions.SET_SHOW_RECROP:
      return { ...state, showRecrop: action.showRecrop };
    case actions.CLEAR_STATE:
      return { ...initialState };
    case actions.SET_COMPLETED:
      return { ...state, completed: action.completed, isLoading: false };
    case actions.SET_COLOR: {
      const newImages = [...state.images];
      newImages[state.batchIndex] = {
        ...state.images[state.batchIndex],
        [action.color]: action.value,
      };
      return { ...state, images: newImages };
    }
    case actions.SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case actions.SET_CROP: {
      const newImages = [...state.images];
      const { url, crop } = action.crop;
      const modifiedCrop = newImages[state.batchIndex].crops[action.name];
      newImages[state.batchIndex].crops = {
        ...newImages[state.batchIndex].crops,
        [action.name]: {
          isDone: true,
          url,
          crop: {
            ...(modifiedCrop ? modifiedCrop.crop : null),
            ...crop,
          },
        },
      };

      return {
        ...state,
        images: newImages,
      };
    }
    case actions.SET_TAB:
      return { ...state, tabIndex: action.tabIndex };
    case actions.NEXT_PAGE:
      if (state.images[state.batchIndex].cropSlug == null) {
        const images = [...state.images];
        images[state.batchIndex] = {
          ...images[state.batchIndex],
          cropHasError: true,
          cropSlug: '',
        };
        return { ...state, images };
      }
      return {
        ...state,
        tabIndex: 0,
        batchIndex: state.batchIndex + 1,
      };
    case actions.GO_BACK:
      return {
        ...state,
        tabIndex: 0,
        batchIndex: state.batchIndex - 1,
      };
    case actions.SET_META_FORM: {
      const { notes } = state;
      let { slug } = state;

      // remove dangling underscore if there is one
      if (slug && slug[slug.length - 1] === '_') {
        slug = state.slug.slice(0, -1);
      }

      return {
        ...state,
        batchIndex: 0,
        images: clearImages(state.images, slug, notes),
      };
    }
    case actions.SET_NOTES: {
      if (action.image) {
        const images = [...state.images];
        images[state.batchIndex] = {
          ...action.image,
          cropNotes: action.notes,
        };
        return { ...state, images };
      }

      return {
        ...state,
        notes: action.notes,
      };
    }
    case actions.SET_SLUG: {
      const isReviewForm = !!action.image;
      const hasError = !isSlugValid(
        !isReviewForm,
        action.slug,
        state.images.length > 1 && !isReviewForm,
      );

      if (action.image) {
        const images = [...state.images];
        images[state.batchIndex] = {
          ...action.image,
          cropHasError: hasError,
          cropSlug: action.slug,
        };
        return { ...state, images };
      }

      return {
        ...state,
        hasError,
        slug: action.slug,
      };
    }
    case actions.SET_IMAGES: {
      const isBatch = action.images.length > 1;
      if (isBatch) {
        return {
          ...state,
          images: action.images,
        };
      }

      const filteredImages = clearImages(action.images);
      const showRecrop =
        filteredImages.length === 1 && (filteredImages[0]?.imagemanager?.cropsets || []).length > 0;

      return {
        ...state,
        showRecrop,
        batchIndex: 0,
        images: filteredImages,
      };
    }
    case actions.SET_POSITION:
      return {
        ...state,
        batchIndex: action.batchIndex,
        tabIndex: action.tabIndex,
      };
    case actions.SORT_IMAGES: {
      const order = {};
      action.order.forEach((id, i) => {
        order[id] = i;
      });

      const images = [...state.images].sort((a, b) => order[a.id] - order[b.id]);
      return { ...state, images };
    }
    default:
      return state;
  }
}
