import get from 'lodash/get';

import constantsUtil from '../../../../../helpers/constantsUtil';

/**
 * **Batch-Crop**
 *
 * Given a `slug` and the batchIndex, `index`, return a new slug with the serial number of the image
 * appended onto the end.
 *
 * *NOTE* `index` starts at 0, add 1 for serial number.
 *
 * @param {string} slug A slug either already associated with a crop, or input by a user
 * @param {number} index The position of the current image in the image batch
 * @returns {string}
 */
export const serializeSlug = (slug, index) => {
  if (!slug || !slug.length) return '';

  return index < 9 ? `${slug}_0${index + 1}` : `${slug}_${index + 1}`;
};

/**
 * Choose the appropriate initial slug given the circumstance (recrop a not submitted image,
 * serialized slug, recrop or new slug).
 *
 * @param {number} batchIndex A number representing which position we are in during batch-crop
 * (regular crop sets will be 0)
 * @param {import('../../Types/d').ImageAPIReqBodies} imageAPIReqBodies An array of all image data
 * to be sent to image API. Used in batch-crop to send submit to image API and downstream
 * @param {import('../../Types/d').ImageData} imageData Metadata relating to the image being
 * currently cropped
 * @param {import('../../Types/d').MetaFormData} metaFormData Batch-crop form that provides default
 * info for each crop set
 * @returns {string}
 */
export const getInitialSlug = (batchIndex, imageData, slug) => {
  // recrop of a cropset that hasn't yet been submitted --- batch + regular
  // if (get(imageAPIReqBodies[batchIndex], 'slug')) {
  //   return imageAPIReqBodies[batchIndex].slug;
  // }

  // batch-crop --- serialize slug
  if (slug) {
    return serializeSlug(slug, batchIndex);
  }

  // crop-set recrop
  if (get(imageData, 'cropData.slug')) {
    return imageData.cropData.slug;
  }

  // there is no available slug
  return null;
};

/**
 * Check whether or not a given `slug` is valid under several differing condtions (batch-crop,
 * serialized, etc.).
 *
 * @param {boolean} isBatchForm A flag to signify whether the current form is being used as the
 * batch meta form or not
 * @param {boolean} shouldSerializeSlug A flag to signify whether slugs are being serialized or not
 * (used for batch-crop)
 * @param {string} slug A slug either already associated with a crop, or input by a user
 * @returns {boolean}
 */
export const isSlugValid = (shouldSerializeSlug, slug, isBatch = false) => {
  if (isBatch && !slug) return true;

  switch (true) {
    // slug is null --- null is valid so that no errors are thrown when form initially loads
    case slug === null:
      return true;

    // batch-crop with serialized slugs
    case shouldSerializeSlug:
      return slug !== '' && slug.length < 13;

    // regular crop
    default:
      return slug !== '' && slug.length < 16;
  }
};

/**
 * Given a string, use regex to convert it to a(n almost valid) slug. For batch-crop, if the slugs
 * are serialized (if `isSerialized` is true), they will be 3 shorter to accomidate for the serial
 * number appended to the end.
 *
 * For improved UX, leave an extra character, which adds an error class to the slug input. This
 * tells the user that they were cut short.
 *
 * @param {string} slug A slug either already associated with a crop, or input by a user
 * @param {boolean} isSerialized A flag that denotes whether or not the slug will be cut 3
 * characters short by a serial number
 * @returns {string}
 */
export const validateSlug = (slug, isSerialized) => {
  // * possibly invoked with invalid slug
  if (typeof slug === 'string') {
    const cleanedSlug = slug
      .replace(/<([^>]+)>/g, '') // * removes all html --- eg: <div id="foo">hi</div> => hi
      .replace(constantsUtil.regex.escapeCharacters, '_')
      .replace(constantsUtil.regex.slugReplace, '_');

    const cutoffLength = isSerialized ? 13 : 16;
    const num = cleanedSlug.length < cutoffLength ? 0 : cleanedSlug.length - cutoffLength;
    const regex = new RegExp(`^_+|.{${num}}$`);

    // cut off characters that go over limit
    return cleanedSlug.replace(regex, '');
  }
  return '';
};
