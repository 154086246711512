import { ConstantsUtil } from '../../helpers';

const TOGGLE_SELECT = 'select/toggle_select';
const IMAGE_SELECTED = 'select/image_selected';
const CLEAR_IMAGES = 'select/clear_images';
const SET_DETAILS = 'select/set_details';
const GET_DETAILS = 'select/get_details';
const CLEAR_ALERT = 'select/max_alert';

export const initialState = {
  isOpen: false,
  selected: {},
  alert: null,
};

export const actions = {
  TOGGLE_SELECT,
  IMAGE_SELECTED,
  CLEAR_IMAGES,
  SET_DETAILS,
  GET_DETAILS,
  CLEAR_ALERT,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DETAILS: {
      const selected = { ...state.selected };
      if (selected[action.id]) {
        selected[action.id].imageDetails = action.image;
        selected[action.id].isLoading = false;
      }
      return { ...state, selected };
    }
    case GET_DETAILS: {
      const selected = { ...state.selected };
      if (selected[action.id]) {
        selected[action.id].isLoading = true;
      }
      return { ...state, selected };
    }
    case CLEAR_ALERT:
      return { ...state, alert: null };
    case IMAGE_SELECTED: {
      const selected = { ...state.selected };
      if (selected[action.image.id]) {
        // Removing existing image
        delete selected[action.image.id];
      } else {
        // Adding new image
        const size = Object.keys(state.selected).length;
        if (size >= ConstantsUtil.get('MAX_BATCH_CROP_COUNT')) {
          return {
            ...state,
            alert: `There is a size limit of ${ConstantsUtil.get(
              'MAX_BATCH_CROP_COUNT',
            )} for Batch Crop.
            Please unselect an image before selecting another.`,
          };
        }
        selected[action.image.id] = action.image;
      }
      return { ...state, selected };
    }
    case CLEAR_IMAGES:
      return { ...state, selected: {} };
    case TOGGLE_SELECT:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
}
