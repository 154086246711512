import NewsroomFetchAPI from '@newsroom-dev/api-fetch';
import { useAuth } from '@screentone/addon-auth-wrapper';


const v2Endpoints = Object.freeze({
  'delete.image': {
    authenticated: true,
    method: 'DELETE',
    path: 'images/:imageId',
  },
  'get.search': {
    authenticated: true,
    method: 'GET',
    path: 'search',
  },
  'get.search.crops': {
    authenticated: true,
    method: 'GET',
    path: 'search/crops',
  },
  'get.setcrops': {
    authenticated: true,
    method: 'GET',
    path: 'images/cms',
  },
  'get.search.uploads': {
    authenticated: true,
    method: 'GET',
    path: 'search/uploads',
  },
});

const v1Endpoints = Object.freeze({
  'get.changelog': {
    method: 'GET',
    path: '/api/changelog',
    authenticated: true,
    contentType: 'text/markdown',
    options: {
      response: 'text',
    },
  },
  'get.swagger': {
    authenticated: true,
    method: 'GET',
    path: '/api/swagger',
  },
  'get.activityLogs': {
    authenticated: true,
    method: 'GET',
    path: 'activityLogs',
  },
  'get.activityLogs.cropset': {
    authenticated: true,
    method: 'GET',
    path: 'activityLogs/cropset/:cropId',
  },
  'get.activityLogs.image': {
    authenticated: true,
    method: 'GET',
    path: 'activityLogs/image/:uploadLogId',
  },
  'get.activityLogs.myuploads': {
    authenticated: true,
    method: 'GET',
    path: 'activityLogs/myuploads',
  },
  'get.activityLogs.uploads': {
    authenticated: true,
    method: 'GET',
    path: 'activityLogs/uploads',
  },
  'get.property.config': {
    authenticated: true,
    method: 'GET',
    path: '/api/config/:property',
  },
  'get.imageDetails': {
    authenticated: true,
    method: 'GET',
    path: 'imageDetails/:imageId',
  },
  'get.imageDetails.cropset': {
    authenticated: true,
    method: 'GET',
    path: 'imageDetails/cropset/:cropId',
  },
  'get.search': {
    authenticated: true,
    method: 'GET',
    path: 'search',
  },
  'get.sizeData': {
    authenticated: true,
    method: 'GET',
    path: 'sizeData',
  },
  'get.users': {
    authenticated: true,
    method: 'GET',
    path: 'users',
  },
  'post.clearCache': {
    authenticated: true,
    method: 'POST',
    path: 'clearCache/:cropId',
  },
  'post.crop': {
    authenticated: true,
    method: 'POST',
    path: 'crop/:cropId',
  },
  'post.image.upload': {
    authenticated: true,
    contentType: null,
    method: 'POST',
    options: { request: 'formData' },
    path: 'image/upload',
  },
  'post.image.upload.sized': {
    authenticated: true,
    contentType: null,
    method: 'POST',
    options: { request: 'formData' },
    path: 'image/upload/sized',
  },
  'post.publishDownstream': {
    authenticated: true,
    method: 'POST',
    path: 'publishDownstream/:cropId',
  },
  'post.sendEmail': {
    authenticated: true,
    contentType: 'application/json; charset=utf-8',
    method: 'POST',
    path: 'sendEmail',
  },
  'put.crop': {
    authenticated: true,
    method: 'PUT',
    path: 'crop/:cropId',
  },
  'put.imageDetails.metadata': {
    authenticated: true,
    method: 'PUT',
    path: 'imageDetails/:imageId/metadata',
  },
});

function client({ accessToken, property = '', resourceServerUri = '' }) {
  const v1Url = property ? `${resourceServerUri}${property}/` : resourceServerUri;
  const v2Url = property ? `${resourceServerUri.replace(/v1/, 'v2')}${property}/` : resourceServerUri;

  const apiFetchV1 = new NewsroomFetchAPI(v1Url, v1Endpoints, { catchHTTPErrors: true });
  const apiFetchV2 = new NewsroomFetchAPI(v2Url, v2Endpoints, { catchHTTPErrors: true });

  apiFetchV1.use(async (name, definition, fetchInit) => {
    if (definition.contentType !== null) {
      const contentType = definition.contentType || 'application/json';
      fetchInit.headers.set('Accept', contentType);
      fetchInit.headers.set('Content-Type', contentType);
    }
    if (definition.authenticated) {
      fetchInit.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return fetchInit;
  });

  apiFetchV2.use(async (name, definition, fetchInit) => {
    if (definition.contentType !== null) {
      const contentType = definition.contentType || 'application/json';
      fetchInit.headers.set('Accept', contentType);
      fetchInit.headers.set('Content-Type', contentType);
    }
    if (definition.authenticated) {
      fetchInit.headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return fetchInit;
  });

  return { ...apiFetchV1, v2: apiFetchV2 };
}


export function useApi() {
  const { app: { resourceServerUri }, authState, currentProperty } = useAuth();
  const options = {
    accessToken: authState?.accessToken?.accessToken,
    property: currentProperty,
    resourceServerUri,
  };
  const api = client(options);
  return api;
}
