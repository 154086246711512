import { setGAMSMessage } from '../../../../../helpers/cropping/setMessage';

/* eslint-disable no-alert, no-await-in-loop, import/prefer-default-export */

/**
 * ! DOCS
 *
 * **Batch-Crop**
 *
 * @param {} api // ! ...
 * @param {import('../../Types/d').CropData} imageAPIRes The full set of data for a crop after
 * completion
 * @param {string} currentProperty Current property (ie. 'wsj', 'barrons', etc.)
 * @returns {import('../../Types/d').CropData}
 */
const publishBatchDownstream = (api, imageAPIRes) =>
  api.post
    .publishDownstream(imageAPIRes.id, {}, JSON.stringify(imageAPIRes))
    .catch((err) => console.error('PUBLISH_DOWNSTREAM ERROR', err));

/**
 * ! DOCS
 *
 * **Batch-Crop**
 *
 * @param {} api // ! ...
 * @param {import('../d').CropData} cropData Metadata about the image being cropped submitted to
 * methode
 * @param {string} id A string representing the id of the image currently being published
 * @param {string} currentProperty Current property (ie. 'wsj', 'barrons', etc.)
 * to the current state in `CropContext`
 * @returns {import('../../Types/d').CropData}
 */
const publishCrop = (api, cropData, id, currentProperty) =>
  api.post.crop(id, {}, JSON.stringify(cropData)).catch((error) => {
    setGAMSMessage({ status: 'GAMS_ERROR' }, currentProperty);
    console.error('SAVE_CROP ERROR', error);
  });

/**
 * ! DOCS
 *
 * **Batch-Crop**
 *
 * @param {} api // ! ...
 * @param {import('../d').CropData} cropData Metadata about the image being cropped submitted to
 * methode
 * @param {string} id A string representing the id of the image currently being published
 * @param {string} currentProperty Current property (ie. 'wsj', 'barrons', etc.)
 * to the current state in `CropContext`
 * @returns {import('../../Types/d').CropData}
 */
const updateCrop = (api, cropData, id, currentProperty) =>
  api.put.crop(id, {}, JSON.stringify(cropData)).catch((error) => {
    setGAMSMessage({ status: 'GAMS_ERROR' }, currentProperty);
    console.error('SAVE_CROP ERROR', error);
  });

export const handleBatchPublish = async (
  api,
  images,
  currentProperty,
  canPublishDownstream,
  isRecrop = false,
) => {
  const request = isRecrop ? updateCrop : publishCrop;
  const cropRequests = images.map((img) => {
    const crops = [];
    img.sizes.forEach((size) => {
      const formattedCrop = {
        ...img.crops[size.name].crop,
        size: size.name,
        displayName: size.displayName,
      };

      if (formattedCrop.aspect) delete formattedCrop.aspect;
      if (formattedCrop.createdAt) delete formattedCrop.createdAt;
      if (formattedCrop.updatedAt) delete formattedCrop.updatedAt;
      if (formattedCrop.unit) delete formattedCrop.unit;
      if (!formattedCrop.scale) formattedCrop.scale = 1;

      if (formattedCrop.size.includes('custom_')) {
        formattedCrop.seed = false;
        formattedCrop.softcrop = true;
      }

      crops.push(formattedCrop);
    });

    return request(
      api,
      {
        crops,
        caption: img.caption,
        credit: img.credit,
        freeze: false,
        meta: { color: { background: img.background, prominent: img.prominent } },
        notes: img.cropNotes,
        slug: img.cropSlug,
      },
      isRecrop ? img.recropId : img.id,
      currentProperty,
    );
  });

  const cropResponse = await Promise.all(cropRequests);

  if (canPublishDownstream) {
    const downstreamRequests = cropResponse
      .filter((res) => res)
      .map((response) => publishBatchDownstream(api, response, currentProperty));
    await Promise.all(downstreamRequests);
  }

  if (isRecrop && cropResponse[0]?.slug) {
    api.post.clearCache(images[0].recropId, {});
  }

  return cropResponse.map((res) => res?.id);
};

export const getCropset = (api, cropsetId) =>
  api.get.imageDetails
    .cropset(cropsetId)
    .then((data) => data)
    .catch(() => []);
