import ConstantsUtil from './constantsUtil';
import labelUtil from './labelUtil';

const isFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return false;
  }
};

/* eslint-disable import/prefer-default-export */
export { isFrame, ConstantsUtil, labelUtil };
