import assign from 'lodash/assign';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import set from 'lodash/set';

import buildImageMetaData from './buildImageMetaData';
import getInitialImageState from './getInitialImageState';
import { getPalette } from '../../../components/colorPicker/getPalette';
import { handleAcceptedFilesErrors, handleRejectedFilesErrors } from './handleFileErrors';

/**
 * Given all of the pieces of data to build a piece of image state, create and return a new object
 * that represents an image.
 *
 * @param {import('../../Types/d').FormData} formData Represents the `formData` object on any given
 * image in the `images` array on `UploadContext`
 * @param {import('../../Types/d').MetaData} metaData Represents the `metaData` object on any given
 * image in the `images` array on `UploadContext`
 * @param {string} uuid A universally unique id, created with each image submitted by the user
 * @returns {import('../../Types/d').Image}
 */
function buildImagesState(formData, metaData, uuid) {
  return { formData, metaData, validation: {}, uuid };
}

/**
 * Using a template for the data shape of type `Image`, populate the template with the relevant data
 * from the given `file`.
 *
 * @param {import('../../Types/d').MetaData} file An object representing the metadata of a file
 * @param {boolean} isPromoUpload A flag that indicates whether or not the current upload is for
 * promo images
 * @returns {import('../Types/d').Image}
 */
function populateTemplate(config, file, isPromoUpload) {
  const metaMap = config.get('app.IMAGE_METADATA_MAP', {});
  const uploadFormConfig = config.get(
    `app.UPLOAD.${isPromoUpload ? 'SIZED_' : ''}IMAGE_META_VALIDATION`,
  );

  const template = getInitialImageState(config, isPromoUpload);

  // assign values to template
  template.metaData = file.metaData;
  template.uuid = file.uuid;
  assign(template.formData, file.formData);

  // set fields that are valid / invalid
  forOwn(template.validation.invalidFields, (_, key) => {
    if (
      (get(uploadFormConfig[key], 'required', false) && !template.formData[key]) ||
      get(template.formData[key], 'length', 0) > get(metaMap[key], 'charLimit')
    ) {
      set(template.validation.invalidFields, key, true);
      if (template.validation.isValid) set(template.validation, 'isValid', false);
    }
  });

  return template;
}

/**
 * Given an array of images, `images`, check if duplicate dimensions exist within the array. Return
 * an object containing the ids of all images that have duplicate dimensions.
 *
 * @param {import('../../Types/d').MetaData[] | import('../../Types/d').Images} images1 An array of
 * images
 * @param {import('../../Types/d').Images} images2 An additional array of images
 * @returns {Object<string, boolean>}
 */
function getDuplicateSizes(images1, images2 = []) {
  const sizes = {};
  const dupes = {};

  const checkForDupes = (image) => {
    const key = `${image.metaData.width}x${image.metaData.height}`;

    if (sizes[key]) {
      dupes[image.uuid] = true;
      if (!dupes[sizes[key]]) dupes[sizes[key]] = true;
    } else {
      sizes[key] = image.uuid;
    }
  };

  images1.forEach((file) => checkForDupes(file));
  images2.forEach((image) => checkForDupes(image));

  return dupes;
}

/**
 * Given an array of image data, `files`, create a new array with the data from `files` transformed
 * into the shape of `images` and set state in `UploadContext` to include these new images. Also
 * preform validation on these images.
 *
 * @param {} // ! ...
 * @param {import('../../Types/d').MetaData[]} files An array of metadata for each file the user has
 * submitted to be uploaded
 * @param {import('../../Types/d').Images} images An array containing all of the images that a user
 * has submitted and all of the relevant data for each image. This state is held on `UploadContext`
 * @returns {void}
 */
function getImageState(config, files, images) {
  const newImages = files.map((file) => populateTemplate(config, file, false));
  const newImagesState = images.concat(newImages);

  const invalidImages = newImagesState
    .filter((image) => !image.validation.isValid)
    .map((image) => image.uuid);

  return { images: newImagesState, invalidImages, isDragActive: false };
}

/**
 * Given an array of image data, `files`, create a new array with the data from `files` transformed
 * into the shape of `images` for **Promo Upload** and set state in `UploadContext` to include these
 * new images. Also preform validation on these images.
 *
 * @param {import('../../Types/d').MetaData[]} files An array of metadata for each file the user has
 * submitted to be uploaded
 * @param {import('../../Types/d').Images} images An array containing all of the images that a user
 * has submitted and all of the relevant data for each image. This state is held on `UploadContext`
 * @param {string[]} palette An array of hex-code strings representing prominent colors in the image
 * @param {Function} setUploadState A function that sets the state of whichever parts of
 * `UploadContext` have been passed to it
 * @returns {void}
 */
async function getPromoImageState(config, files, images, palette) {
  const duplicateSizeIds = getDuplicateSizes(files, images);
  const newImagesState = images.concat(files.map((file) => populateTemplate(config, file, true)));

  let newPalette = palette;

  if (images.length === 0) {
    const getHex = (paletteObject) => paletteObject.hex;
    const droppedFilesPalette = await getPalette(files[0].metaData.path);

    newPalette = droppedFilesPalette.map(getHex);
  }

  // TODO: replace for map to avoid reference mutations
  newImagesState.forEach((image) => {
    if (duplicateSizeIds[image.uuid]) {
      image.validation.isDuplicate = true; /* eslint-disable-line no-param-reassign */
      newImagesState[0].validation.isValid = false; // * only the 1st image holds state for promo
    } else {
      image.validation.isDuplicate = false; /* eslint-disable-line no-param-reassign */
    }
  });

  // set default of prominent color to color with highest population
  if (images.length === 0) {
    newImagesState[0].formData.prominentColor = newPalette[0];
  }

  return {
    images: newImagesState,
    invalidImages: newImagesState[0].validation.isValid ? [] : [newImagesState[0].uuid],
    isDragActive: false,
    palette: newPalette,
    selectedImages: newImagesState.map((image) => image.uuid),
  };
}

/**
 * Given a user uploaded image file, `file`, parse the metadata from the file then create and return
 * and new object of type `Image`.
 *
 * @param {} config // ! ...
 * @param {import('../../Types/d').MetaData} file An object representing the metadata of a file
 * @param {import('../../../../../components/Types/d').User} user
 * An object provided by Okta that contains various user data
 * @returns {import('../../Types/d').Image}
 */
async function handleFormData(config, file, user) {
  const formData =
    file.type === 'image/jpeg'
      ? await buildImageMetaData(config, file, user)
      : { slug: file.Headline || file.name || '', contact: user.displayName || user.name || '' };
  return buildImagesState(formData, file, file.uuid);
}

/**
 * Given an array of `acceptedFiled` and `rejectedFiles`, generate a list of error messages by
 * iterating through each array, set state on `DropZone` to include these error messages and set
 * state on `UploadContext` to include the new images in `acceptedFiles`.
 *
 * @param {import('../../Types/d').MetaData[]} acceptedFiles An array of files the user submitted
 * @param {} api // ! ...
 * @param {} config // ! ...
 * @param {import('../../Types/d').Images} images An array containing all of the images that a user
 * has submitted and all of the relevant data for each image. This state is held on `UploadContext`
 * @param {boolean} isPromoUpload A flag that indicates whether or not the current upload is for
 * promo images
 * @param {string[]} palette An array of hex-code strings representing prominent colors in the image
 * @param {import('../../Types/d').MetaData[]} rejectedFiles An array of files the user submitted
 * that react-dropzone has rejected
 * @param {Function} setUploadState A function that sets the state of whichever parts of
 * `UploadContext` have been passed to it
 * @param {Function} setUploadErrors A function that sets the piece of state in `DropZone` for
 * errors that occur during upload.
 * @param {import('../../../../../components/Types/d').User} user
 * An object provided by Okta that contains
 * various user data
 * @returns {void}
 */
async function handleFileSubmission(
  acceptedFiles,
  api,
  config,
  currentProperty,
  images,
  isPromoUpload,
  palette,
  rejectedFiles,
  user,
) {
  // const acceptedFileTypesArray = config.get('app.UPLOAD.ACCEPT').map((type) => `image/${type}`);
  const acceptedFileTypesArray = ['jpeg', 'png', 'gif'].map((type) => `image/${type}`);

  const [filteredFiles, acceptedFileErrors] = await handleAcceptedFilesErrors(
    acceptedFileTypesArray,
    acceptedFiles,
    api,
    config,
    currentProperty,
    isPromoUpload,
  );

  const rejectedFileErrors = handleRejectedFilesErrors(
    acceptedFileTypesArray,
    isPromoUpload,
    rejectedFiles,
  );

  let uploadErrors = [];
  let newState = { isDragActive: false };

  // display error message for failed uploads
  if (acceptedFileErrors.length !== 0 || rejectedFileErrors.length !== 0) {
    uploadErrors = acceptedFileErrors.concat(rejectedFileErrors);
  }

  if (filteredFiles.length !== 0) {
    if (isPromoUpload) {
      const newImages = filteredFiles.map((file) => {
        const formData = { contact: user.displayName || user.name || '' };
        return buildImagesState(formData, file, file.uuid);
      });

      newState = await getPromoImageState(config, newImages, images, palette);
    } else {
      const handleFormDataTasks = filteredFiles.map(async (file) =>
        handleFormData(config, file, user),
      );
      const newImages = await Promise.all(handleFormDataTasks);

      newState = getImageState(config, newImages, images);
    }
  }

  return [newState, uploadErrors];
}

export { getDuplicateSizes };
export default handleFileSubmission;
