import ExifReader from 'exifreader';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';

import moment from 'moment';

/**
 * Create an object containing useable metadata for a jpeg image uploaded by the user.
 *
 * @param {} config // ! ...
 * @param {import('../../Types/d').MetaData} file Image file uploaded by the user
 * @param {import('../../../../../components/Types/d').User} user
 * An object provided by Okta that contains various user data
 * @returns {import('../../Types/d').FormData}
 */
async function buildImageMetaData(config, file, user) {
  const form = config.get('app.FORMS.UPLOAD', {});
  const metaMap = config.get('app.IMAGE_METADATA_MAP', {});
  const uploadProps = config.get('app.UPLOAD', {});

  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
      reader.onload = (e) => {
        const metadata = {};
        const tags = ExifReader.load(e.target.result);

        if (tags['Color Space'] || tags.ColorSpace) {
          const colorSpaceTag = tags['Color Space'] || tags.ColorSpace;
          const colorSpaceDescription = `${colorSpaceTag.description}`;
          metadata.colorSpace = colorSpaceDescription.trim();
        }

        forOwn(metaMap, (_, formFieldKey) => {
          const tagValue = tags[capitalize(camelCase(formFieldKey))];
          // user.displayName => contact
          if (formFieldKey === 'contact') {
            metadata[formFieldKey] =
              form[formFieldKey].default === '$USER'
                ? user.displayName || user.name // ! == added after the fact?
                : form[formFieldKey].default;

            // make sure that keywords and category are properly formatted array
          } else if (formFieldKey === 'keywords' || formFieldKey === 'category') {
            if (Array.isArray(tagValue)) {
              metadata[formFieldKey] = tagValue.map((keyword) => keyword.description);
            } else if (tagValue) {
              metadata[formFieldKey] = [tagValue.description];
            } else {
              metadata[formFieldKey] = [];
            }

            // tags.Headline => slug
          } else if (formFieldKey === 'slug') {
            metadata.slug = get(tags, 'Headline.description') || file.name || '';

            // tags[DateCreated || Date Created || DateTime] => date
          } else if (formFieldKey === 'date') {
            let isValid = true;
            let dateFieldName;
            if (tags.DateCreated) dateFieldName = 'DateCreated';
            else if (tags['Date Created']) dateFieldName = 'Date Created';
            else if (tags.DateTime) dateFieldName = 'DateTime';
            else isValid = false;

            if (
              isValid &&
              (!moment(tags[dateFieldName].description, 'YYYY:MM:DD', true).isValid() ||
                !moment(tags[dateFieldName].description, 'YYYY:MM:DD HH:mm:ss', true).isValid() ||
                !moment(tags[dateFieldName].description, 'YYYY-MM-DDTHH:mm:ss', true).isValid())
            ) {
              // YYYY:MM:DD HH:mm:ss
              if (moment(tags[dateFieldName].description, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
                metadata[formFieldKey] = moment(
                  tags[dateFieldName].description,
                  'YYYY-MM-DDTHH:mm:ss',
                ).format('YYYY:MM:DD');

                // YYYY:MM:DD HH:mm:ss
              } else if (moment(tags[dateFieldName].description, 'YYYY:MM:DD HH:mm:ss').isValid()) {
                metadata[formFieldKey] = moment(
                  tags[dateFieldName].description,
                  'YYYY:MM:DD HH:mm:ss',
                ).format('YYYY:MM:DD');

                // YYYY:MM:DD
              } else if (moment(tags[dateFieldName].description, 'YYYY:MM:DD').isValid()) {
                metadata[formFieldKey] = moment(
                  tags[dateFieldName].description,
                  'YYYY:MM:DD',
                ).format('YYYY:MM:DD');

                // not valid
              } else {
                metadata[formFieldKey] = null;
              }
            } else if (isValid) {
              metadata[formFieldKey] = tags[dateFieldName].description;
            } else {
              metadata[formFieldKey] = null;
            }

            // tags.Caption/Abstract => caption
          } else if (formFieldKey === 'caption') {
            metadata[formFieldKey] = get(tags, 'Caption/Abstract.description', '');

            // tags.[PublishedCredit || Published Credit] => caption
          } else if (formFieldKey === 'credit') {
            metadata[formFieldKey] =
              get(tags, 'PublishedCredit.description') ||
              get(tags, 'Published Credit.description') ||
              get(tags, 'Credit.description') ||
              '';

            // graphic type
          } else if (formFieldKey === 'type') {
            const graphicType = tags.GraphicType
              || tags.graphicType || tags.graphictype || tags.type || tags.Type;

            metadata.type = graphicType && get(metaMap, 'type.options', []).some(option => option === graphicType?.description)
              ? graphicType.description
              : 'Photo';

            // set other fields that dont require special handling
          } else {
            metadata[formFieldKey] = tagValue ? tagValue.description : '';
          }
        });

        if (uploadProps.IMAUTOPUB && uploadProps.IMAUTOPUB_BY_DEFAULT) {
          metadata.IMAUTOPUB = uploadProps.IMAUTOPUB_BY_DEFAULT;
          metadata.keywords =
            uploadProps.IMAUTOPUB_BY_DEFAULT && Array.isArray(metadata.keywords)
              ? metadata.keywords.concat('IMAUTOPUB')
              : ['IMAUTOPUB'];
        }

        resolve(metadata);
      };
    } catch (e) {
      reject(e);
    }
  });
}

export default buildImageMetaData;
