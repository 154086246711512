import React from 'react';

import compact from 'lodash/compact';
import get from 'lodash/get';
import { Token } from '@screentone/core';

import DynamicIcon from '../components/DynamicIcon';

const labelUtil = {
  getLabels(image, currentProperty, labels, displayText) {
    return labels.map((label) => {
      const content = Array.isArray(label.content)
        ? compact(label.content.map(labelName => get(image, labelName)))[0]
        : label.content;

      const labelElem = (
        <div
          key={label.title}
          data-cy="image-container-item-ribbon"
          title={label.title}
          className={`ui ribbon icon label ${label.color} ${label.hideOnImageDetails ? 'hideOnPhotoPage' : ''}`}
        >
          <i className={`${label.icon === 'award' ? 'star' : label.icon} icon`} />{displayText ? label.title : ''}
        </div>
      );

      if (content && label.keyword) {
        const keyword = label.keyword.replace('$Publication', `${currentProperty.toUpperCase()}`);
        if ((typeof content === 'string' ? content.split(',') : content).includes(keyword)) {
          return labelElem;
        }

        // content is `imagemanager.cropsets.length` for crop label
      } else if (label.title === 'Cropped' && content) {
        return labelElem;
      }
      return null;
    }).filter(label => label !== null);
  },

  getTokens(config, image, currentProperty, displayText) {
    const labels = config.get('app.SEARCH_LABELS', []);

    return labels.map((label) => {
      const content = compact(Object.keys(label.content).map(c => get(image, label.content[c])))[0];
      const labelElem = (
        <Token key={label.keyword} icon={DynamicIcon({ icon: label.icon })} color="blurple" margin={{ right: 'sm' }}>
          {displayText ? label.title : ''}
        </Token>
      );
      if (content && label.keyword) {
        const keyword = label.keyword.replace('$Publication', `${currentProperty.toUpperCase()}`);
        const contentArray = (typeof content === 'string' ? content.split(',') : content);
        if (contentArray.includes(keyword)) {
          return labelElem;
        }
      } else if (content && !label.keyword) {
        if (get(image, content)) {
          return labelElem;
        }
      }
      return null;
    }).filter(label => label !== null);
  },
};

export default labelUtil;
