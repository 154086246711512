import filter from 'lodash/filter';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import unionBy from 'lodash/unionBy';
import Vibrant from 'node-vibrant';
import values from 'lodash/values';
import SmartCrop from 'djtools.smartcrop';

const cropUtil = {
  getCropData(formData, selectedCrops, previousCrops, sizes) {
    const selectedCropArr = Object.keys(selectedCrops).map((key) => {
      const { crop } = selectedCrops[key];
      crop.size = key;
      if (crop.aspect) delete crop.aspect;
      if (crop.createdAt) delete crop.createdAt;
      if (crop.updatedAt) delete crop.updatedAt;
      if (crop.unit) delete crop.unit;
      if (!crop.scale) crop.scale = 1;

      return crop;
    });

    const formattedSelectedCrops = cropUtil.getFormattedCrops(
      selectedCropArr,
      previousCrops,
      formData,
      sizes,
    );

    return ({
      altCaption: formData.altCaption,
      caption: formData.caption || formData.Caption,
      credit: formData.credit || formData.Credit,
      crops: formattedSelectedCrops,
      freeze: false,
      notes: formData.notes,
      slug: formData.slug,
      meta: {
        color: {
          background: formData.background,
          prominent: formData.prominent,
        },
      },
    });
  },

  getFormattedCrops(selectedCrops, previousCrops, formData, sizes) {
    return selectedCrops.map((crop) => {
      const currentSize = sizes.filter(size => size.name === crop.size)[0] || {};
      const displayName = currentSize.displayName || (crop.size && crop.size[0].toUpperCase() + crop.size.slice(1)) || `Custom_${crop.outputWidth}x${crop.outputHeight}`;
      const size = crop.size || `custom_${crop.outputWidth}x${crop.outputHeight}`;
      const shouldSoftcrop = (!formData.isReCrop ? currentSize.softcrop : crop.softcrop) || ((crop.size && crop.size.match(/custom_/)) && formData.sendCustomCrop) || false;
      const c = {
        displayName,
        height: crop.height,
        outputHeight: crop.outputHeight,
        outputWidth: crop.outputWidth,
        scale: 1,
        seed: crop.seed,
        size,
        softcrop: shouldSoftcrop,
        width: crop.width,
        x: crop.x,
        y: crop.y,
      };

      // add the id to the crop if we have a previous crop
      if (previousCrops.length) {
        const selectedPreviousCrop = previousCrops.find(prevCrop =>
          prevCrop.size === crop.size ||
          (!prevCrop.size &&
            +prevCrop.outputHeight === +crop.outputHeight && // for custom crop - size comes in null
            +prevCrop.outputWidth === +crop.outputWidth));
        if (get(selectedPreviousCrop, 'id')) c.id = selectedPreviousCrop.id;
      }

      return c;
    });
  },

  getSoftcropData(formData, selectedCrops, previousCrops, sizes, config) {
    const seedCrops = unionBy(selectedCrops, previousCrops, 'size').filter(crop => crop.seed === true);
    let softcropsSizes = [];

    if (formData.isReCrop) {
      // if it is a recrop, combine the previousCrops and selectedCrops
      // and filter to include only crops that should be softcropped
      const allCrops = uniqBy(selectedCrops.concat(previousCrops), 'size');
      softcropsSizes = filter(allCrops, { softcrop: true });
    } else {
      // if not recrop then add all softcrops
      softcropsSizes = filter(sizes, { softcrop: true });
    }

    return softcropsSizes.map((size) => {
      const isCustom = (size.size || '').match(/custom_/);

      const outputWidth = formData.isReCrop ? size.outputWidth : size.width;
      const outputHeight = formData.isReCrop ? size.outputHeight : size.height;

      const opts = {
        imageWidth: formData.photoWidth,
        imageHeight: formData.photoHeight,
        seedCrops: seedCrops || [],
        outputWidth,
        outputHeight,
        outputScale: 1,
        debug: true,
      };
      const sc = SmartCrop(opts);

      const cb = Date.now();
      const c = {
        x: this.getPercentage(sc.x, formData.photoWidth),
        y: this.getPercentage(sc.y, formData.photoHeight),
        width: this.getPercentage(sc.cropWidth, formData.photoWidth),
        height: this.getPercentage(sc.cropHeight, formData.photoHeight),
        label: isCustom ? size.size[0].toUpperCase() + size.size.slice(1) : size.displayName,
        location: `${config.EXTERNAL_IMAGE_URL}im-${formData.cropId ? formData.cropId : ':cropset_id'}/?size=${isCustom ? size.size : (outputWidth / outputHeight)}${formData.isReCrop ? `&djimcb=${cb}` : ''}`,
      };

      return c;
    });
  },

  getSyndication(formData, config) {
    const sizes = config.SYNDICATION_SIZES;
    const cb = Date.now();

    return sizes ? sizes.map(size => ({
      id: size,
      location: `${config.EXTERNAL_IMAGE_URL}im-${formData.cropId ? formData.cropId : ':cropset_id'}/${size}${formData.isReCrop ? `?djimcb=${cb}` : ''}`,
    })) : false;
  },

  getPercentage(num1, num2) {
    let value = (num1 / num2) * 100;
    if (value > 100) {
      console.error('SMARTCROP ERROR > percentage over 100%');
      value = 100;
    }
    return value;
  },

  async getAutoCrop(img, config) {
    const slug = img.slug ||
      img?.imagemanager?.uploadLog?.slug ||
      img?.metadata?.headline;

    return {
      id: img.id,
      caption: img.caption,
      credit: img.credit,
      freeze: false,
      crops: [
        {
          height: 100,
          outputHeight: img.pixelHeight || img.height,
          outputWidth: img.pixelWidth || img.width,
          scale: 1,
          seed: true,
          softcrop: true,
          width: 100,
          x: 0,
          y: 0,
        },
      ],
      meta: {
        color: {
          background: '#FFFFFF',
          prominent: await Vibrant
            .from(config.get('app.INTERNAL_IMAGE_URL') + img.id)
            .getPalette()
            .then(colors =>
              values(colors).reduce((highestPopColor, color) => (
                color.population > highestPopColor.population
                  ? color
                  : highestPopColor
              ), { hex: '#000000', population: 0 })
                .hex)
            .catch(err => console.error('ERROR: getPalette:', err)),
        },
      },
      slug,
    };
  },

};


export default cropUtil;
