import React, { useEffect, useRef, useState } from 'react';
import { useAuth, LoadingPage, ErrorPage } from '@screentone/addon-auth-wrapper';

import { useApi } from './requestConfig';
import { buildNewConfig } from './utils';

export const ConfigContext = React.createContext();

function ConfigContextProvider({ children }) {
  const { authState = { }, currentProperty, user, userAccess } = useAuth();
  const isAuthenticated = authState?.isAuthenticated;
  const api = useApi();
  const [propertyConfig, setConfig] = useState({});
  const storedSearchOptionsString = userAccess('enableNewUploadSearch')
    ? localStorage.getItem('im.searchOptions') : JSON.stringify({ crop: 'new', upload: 'new' });
  const [searchOptions, setSearchOptionsFn] = useState(JSON.parse(storedSearchOptionsString));

  useEffect(() => {
    const enableNewUploadSearch = userAccess('enableNewUploadSearch');
    if (enableNewUploadSearch && Object.keys(searchOptions).length === 0) {
      localStorage.setItem('im.searchOptions', JSON.stringify({ crop: 'new', upload: 'new' }));
      setSearchOptionsFn({ crop: 'new', upload: 'new' });
    }
  }, [searchOptions, setSearchOptionsFn, user, userAccess]);

  const setSearchOptions = (newSearchOptions) => {
    if (userAccess('enableNewCropSearch')) {
      localStorage.setItem('im.searchOptions', JSON.stringify(newSearchOptions));
      setSearchOptionsFn(newSearchOptions);
    }

    if (userAccess('enableNewUploadSearch')) {
      localStorage.setItem('im.searchOptions', JSON.stringify(newSearchOptions));
      setSearchOptionsFn(newSearchOptions);
    }
  };

  // * REQUEST FLAGS: null = no request, false = in flight, true = config received
  const isConfigLoadedRef = useRef(null);
  const hasConfigErrorRef = useRef(null);

  useEffect(() => {
    const isAuthenticatedWithoutConfig =
      isAuthenticated && (currentProperty && !propertyConfig[currentProperty]);

    // * reset flag when we go to a new property
    if (isAuthenticatedWithoutConfig && isConfigLoadedRef.current) {
      isConfigLoadedRef.current = null;
      hasConfigErrorRef.current = null;
    }

    if (isAuthenticatedWithoutConfig && isConfigLoadedRef.current === null) {
      api.get.property
        .config(currentProperty)
        .then((config) => {
          setConfig({ ...propertyConfig, [currentProperty]: config });
          isConfigLoadedRef.current = true;
        })
        .catch((err) => {
          console.error(`api.get.property.config(${currentProperty}): `, err);
          isConfigLoadedRef.current = false;
          hasConfigErrorRef.current = true;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, currentProperty]);

  if (currentProperty === null) {
    return (
      <ConfigContext.Provider value={{ api, searchOptions, setSearchOptions }}>
        {children}
      </ConfigContext.Provider>
    );
  }

  // TODO: review usage, ref updates don't trigger a re-render
  console.log('TODO: review usage, ref updates don\'t trigger a re-render', currentProperty);
  if (hasConfigErrorRef.current) return <ErrorPage />;
  if (currentProperty && (currentProperty && !propertyConfig[currentProperty])
    && !isConfigLoadedRef.current === true) { // isConfigLoadedRef.current can be false or null
    return <LoadingPage />;
  }

  const config = buildNewConfig(propertyConfig[currentProperty], currentProperty, user);

  const constants = (currentProperty
    && propertyConfig[currentProperty]
    && propertyConfig[currentProperty].app)
    || {};

  return (
    <ConfigContext.Provider value={{ api, config, constants, searchOptions, setSearchOptions }}>
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigContextProvider;
