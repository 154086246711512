import PropTypes from 'prop-types';
import { IconAward, IconCheck, IconCrop, IconCheckCircle, IconEdit, IconImage, IconImageGallery, IconMinimize, IconUpload, IconStar, IconWarning } from '@screentone/core';

const DynamicIcon = ({ icon }) => {
  let IconComponent;
  switch (icon) {
    case 'award':
      IconComponent = IconAward;
      break;
    case 'crop':
      IconComponent = IconCrop;
      break;
    case 'check':
      IconComponent = IconCheck;
      break;
    case 'check-circle':
      IconComponent = IconCheckCircle;
      break;
    case 'edit':
      IconComponent = IconEdit;
      break;
    case 'minimize':
      IconComponent = IconMinimize;
      break;
    case 'image':
      IconComponent = IconImage;
      break;
    case 'image-gallery':
      IconComponent = IconImageGallery;
      break;
    case 'star':
      IconComponent = IconStar;
      break;
    case 'upload':
      IconComponent = IconUpload;
      break;
    case 'warning':
      IconComponent = IconWarning;
      break;
    default:
      console.warn('MISSING ICON: ', icon);
      break;
  }
  return IconComponent;
};

DynamicIcon.propTypes = {
  icon: PropTypes.string,
};

DynamicIcon.defaultProps = {
  icon: '',
};
export default DynamicIcon;
