import React, { createContext, useReducer } from 'react';
import { actions, initialState, reducer } from './image.reducer';

const ImageContext = createContext({
  state: {},
  dispatch: () => {},
  actions: {},
});

const ImageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <ImageContext.Provider value={{ state, dispatch, actions }}>{children}</ImageContext.Provider>
  );
};

export const useImageContext = () => React.useContext(ImageContext);

export default ImageContextProvider;
