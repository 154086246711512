export const setGAMSMessage = (message, currentProperty) => {
  let alert = {};
  const query = window.location.search.split('?').filter(c => c !== '').map(q => q.split('=')).reduce((acc, c) => ({ [c[0]]: c[1] }), {});
  switch (message.status) {
    case 'GAMS_SUBMIT':
      alert = {
        headline: 'Your crops are being submitted to GAMS',
        text: 'Please wait.',
        type: 'positive',
      };
      break;
    case 'GAMS_RESUBMIT':
      alert = {
        headline: 'Your crops are being resubmitted to GAMS',
        text: 'This may take several minutes.',
        type: 'warning',
        closeable: false,
      };
      break;
    case 'GAMS_WAITING':
      alert = {
        headline: 'Your crops are being submitted to GAMS',
        text: 'This is taking longer than usual. Please wait up to one minute.',
        type: 'warning',
        closeable: false,
      };
      break;
    case 'GAMS_ERROR':
      alert = {
        headline: `Your ${(query && query.cropId) ? 'updated' : 'seed'} crops failed to upload to GAMS`,
        text: 'Crops have been saved in Image Manager',
        type: 'negative',
      };
      break;
    case 'GAMS_FINISH':
      alert = {
        headline: `Your ${(query && query.cropId) ? 'updated' : 'seed'} crops have been saved and submitted to GAMS`,
        text: 'Please check the activity logs tab to see more details. GAMS may take a few minutes to process your crops.',
        type: 'positive',
      };
      break;
    case 'SIZEDUPLOAD_ERROR':
      alert = {
        content: 'Please fill in the required metadata',
        type: 'negative',
        overlay: true,
        compact: true,
      };
      break;
    default:
      alert = null;
  }
  try {
    sessionStorage.setItem(`im.${currentProperty}.message`, JSON.stringify(Object.assign({}, alert, { displayed: true })));
  } catch (error) {
    console.error('setGAMSMessage error: ', error);
  }
};

export const getMessage = (updated, downstream) => ({
  headline: `Your image has been ${updated ? 'updated' : 'published'}${downstream ? ` and submitted to ${downstream}.` : '.'}`,
  text: `Please check the activity logs tab to see more details. ${downstream ? `${downstream} may take a few minutes to process your crops.` : ''} ${updated ? 'We are also clearing this image from our cache.' : ''}`,
  type: 'positive',
});
