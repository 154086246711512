import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  AuthRouter,
  ProtectedRoute,
  LoadingPage,
  ErrorPage,
  useAuth,
} from '@screentone/addon-auth-wrapper';
import { Box, Illustration, Typography } from '@screentone/core';
import '@screentone/core/css/index.css';
import '@screentone/addon-auth-wrapper/css/index.css';
import '@screentone/addon-auth-wrapper/css/Login.css';
import '@screentone/addon-calendar/css/index.css';
import '@screentone/addon-autocomplete/css/index.css';

import PropertyContextProvider from './providers/PropertyContext';
import ConfigContextProvider, { ConfigContext } from './providers/ConfigContext';
import UploadProvider from './providers/Upload';
import { isFrame } from './helpers';
import Version from './components/Version';
import PageWrapper from './components/PageWrapper';

import './App.css';
import SelectContextProvider from './providers/SelectContext';
import NewSearchOptions from './components/NewSearchOptions';
import CropContextProvider from './providers/CropContext';
import ImageContextProvider from './providers/ImageContext';

const Search = React.lazy(() => import('./routes/property/Search'));
const ImageDetails = React.lazy(() => import('./routes/property/ImageDetails'));
const Crop = React.lazy(() => import('./routes/property/Crop'));
const Upload = React.lazy(() => import('./routes/property/Upload/DropZone'));
const Profile = React.lazy(() => import('./routes/profile'));
const Developer = React.lazy(() => import('./routes/developer'));
const ChangeLog = React.lazy(() => import('./routes/ChangeLog'));

function HeaderBarHook() {
  const { userAccess } = useAuth();
  const header = {
    sticky: true,
    userLinks: [{
      text: 'Developer Docs',
      href: '/developer',
      restricted: 'developer',
    }],
  };
  if (userAccess('enableNewCropSearch') || userAccess('enableNewUploadSearch')) {
    header.settings = {
      items: [
        {
          content: <NewSearchOptions />,
        },
      ],
    };
  }
  return header;
}

function App() {
  const [baseConfig, setBaseConfig] = useState(null);
  const [error, setError] = useState(null);
  const headless = isFrame();

  useEffect(() => {
    fetch('/api/config')
      .then(async (res) => {
        const response = await res.json();
        const config = { ...response };

        config.app.clientVersion = config.app.version;
        config.app.apiVersion = config.app.api;
        config.app.version = (
          <Version
            apiVersion={config.app.apiVersion}
            toolVersion={config.app.clientVersion}
          />
        );

        ReactGA.initialize(response.app.GA_CODE);

        // ! is this the place for this?
        const urlParams = new URLSearchParams(window.location.search);
        const iframeRef = urlParams.get('ref');
        const iframeSource = urlParams.get('source');

        if (isFrame()) {
          config.app.useThemes = false;
        }

        if (sessionStorage) {
          try {
            if (iframeRef) {
              sessionStorage.setItem('im.IFRAME_REF', iframeRef);
            }

            if (iframeSource) {
              sessionStorage.setItem('im.IFRAME_SOURCE', iframeSource.toLowerCase());
            }

            // eslint-disable-next-line no-shadow
          } catch (error) {
            console.error('error: ', error);
            setError(error);
          }
        }

        setBaseConfig(config);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  if (!baseConfig) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage type={error.status} />;
  }

  // ! move search and property providers to wrap router directly
  function AuthRouterProvider({ children }) {
    const { authState } = useAuth();
    if (authState && !authState.isAuthenticated) {
      return <span>{children}</span>;
    }
    return (
      <ConfigContextProvider>
        <ImageContextProvider>
          <SelectContextProvider>
            <UploadProvider>
              <CropContextProvider>
                <PropertyContextProvider>{children}</PropertyContextProvider>
              </CropContextProvider>
            </UploadProvider>
          </SelectContextProvider>
        </ImageContextProvider>
      </ConfigContextProvider>
    );
  }

  const BrandRedirect = () => {
    const { config } = useContext(ConfigContext);
    const cldURL = config.get('app.CLD_URL');
    const cldRedirectArray = config.get('app.CLD_REDIRECTS') || [];
    const PUBLIC_URL = config.get('app.PUBLIC_URL');

    const { user } = useAuth();
    const { params } = useRouteMatch();
    const location = useLocation();

    if (cldRedirectArray.includes(params.property)) {
      const searchParams = {
        ...Object.fromEntries(new URLSearchParams(location.search)),
        iss: PUBLIC_URL,
      };
      const pathArr = location.pathname.split('/');
      let redirectPath = '';
      switch (pathArr[2]) {
        case 'image':
          redirectPath = `/image/${pathArr[3]}`;
          break;
        case 'upload':
          redirectPath = '/upload';
          break;
        case 'my-uploads':
          searchParams.user = user.dj_user_id;
          break;
        case 'crop':
        case 'crop-logs':
        case 'upload-logs':
        default:
          redirectPath = '';
          break;
      }

      window.location.href = `${cldURL}/${
        params.property
      }${redirectPath}?${new URLSearchParams(searchParams)}`;

      return (
        <Box
          style={{
            minWidth: '400px',
            maxWidth: '80vw',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          margin={{ vertical: 'xl' }}
          padding={{ all: 'mlg' }}
        >
          <Illustration
            style={{
              width: '250px',
              margin: 'var(--st-spacer-md) auto',
            }}
            name="empty"
          />
          <Typography variant="header2" align="center">Redirecting...</Typography>
          <Typography variant="bodytext">You will be redirect to <Typography variant="link" componentEl="a" href={`${cldURL}/${params.property}${redirectPath}`} inline>{`${cldURL}/${params.property}`}</Typography></Typography>
        </Box>
      );
    }
    return (
      <Switch>
        <ProtectedRoute exact path="/:property/image/:imageId" component={ImageDetails} />
        <ProtectedRoute exact path="/:property/image/:imageId/crop" component={Crop} />
        <ProtectedRoute exact path="/:property/crop/batch" component={Crop} />
        <ProtectedRoute exact path="/:property/upload" component={Upload} />
        <ProtectedRoute exact path="/:property/upload/sized" component={Upload} />
        <ProtectedRoute path="/:property" component={Search} />
      </Switch>
    );
  };

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <AuthRouter
        app={baseConfig.app}
        authConfig={baseConfig.auth}
        provider={(props) => AuthRouterProvider(props)}
        pageWrapper={PageWrapper}
        headerBarHook={HeaderBarHook}
        headerBarOverride={headless && Fragment}
        useCustomLoginPage={headless || baseConfig.isTesting}
      >
        <ProtectedRoute path="/changelog" component={ChangeLog} />
        <ProtectedRoute path="/developer" component={Developer} restricted="developer" />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <Redirect from="/:property/logs" to="/:property/crop-logs" />
        <Redirect from="/:property/upload/myUploads" to="/:property/my-uploads" />
        <Redirect exact from="/:property/image" to="/:property" />
        <ProtectedRoute path="/:property">
          <BrandRedirect />
        </ProtectedRoute>
      </AuthRouter>
    </React.Suspense>
  );
}

export default App;
