import { getInitialSlug, isSlugValid } from '../../routes/property/Crop/PublishTab/utils';

export const clearImages = (images, slug = null, notes = null) => {
  const nonCroppable = new Set();
  const filteredImages = images
    .filter((img) => {
      if (!img.properties.canCrop) {
        nonCroppable.add(img.id);
      }
      return img.properties.canCrop;
    })
    .map((img, i) => {
      const cropHasError = !isSlugValid(false, slug, false);
      return {
        ...img,
        cropSlug: getInitialSlug(i, img, slug),
        cropHasError,
        cropNotes: notes,
      };
    });

  return filteredImages;
};
