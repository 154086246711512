import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { Alert, Toast, IconError } from '@screentone/core';

import { ConfigContext } from '../ConfigContext';
import { useMergeReducer } from '../../helpers/hooks/useMergeReducer';
import handleFileSubmission from './utils/handleFileSubmission';
import handleGetFilesFromEvent from './utils/handleGetFilesFromEvent';
import { getDropzoneConfig } from './utils/dropzoneConfig';

const UploadContext = React.createContext(null);

function useDragAndDrop() {
  const contextValue = useContext(UploadContext);
  if (!contextValue) {
    throw new Error("Can't be used without a DragAndDrop provider.");
  }
  return contextValue;
}

function buildRouteMatcher(currentProperty, pathname) {
  return function matchRoute(routeRegexp) {
    const regexpString = String.raw`\/${currentProperty}${routeRegexp}\/?`;
    const uploadPromoRegex = new RegExp(regexpString, 'gi');
    return uploadPromoRegex.test(pathname);
  };
}

function getInitialState(isPromoUpload) {
  return {
    images: [],
    invalidImages: [],
    isBulkEditFormOpen: isPromoUpload,
    isDragActive: false,
    isPromoUpload,
    palette: [],
    selectedImages: [],
  };
}


function UploadProvider({ children }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { authState, currentProperty, user } = useAuth();
  const { isAuthenticated } = authState || {};
  const { api, config } = useContext(ConfigContext);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [shouldNotify, setShouldNotify] = useState(false);

  const UPLOAD_PATH = useMemo(() => `/${currentProperty}/upload`, [currentProperty]);
  const PROMO_UPLOAD_PATH = useMemo(() => `/${currentProperty}/upload/sized`, [currentProperty]);

  const currentRoute = pathname.endsWith('/')
    ? pathname.substring(0, pathname.length - 1) // removing the trailing slash for route matching
    : pathname;

  const isDefaultUpload = currentRoute === UPLOAD_PATH;
  const isPromoUpload = currentRoute === PROMO_UPLOAD_PATH;

  const isDropDisabled = useMemo(() => {
    const DROP_DISABLED_ROUTES = ['/profile', '/image/.+/crop'];
    const matchesRoute = buildRouteMatcher(currentProperty, currentRoute);
    return currentRoute === '/' || DROP_DISABLED_ROUTES.some(matchesRoute);
  }, [currentProperty, currentRoute]);

  const initialState = useMemo(() => getInitialState(isPromoUpload), [isPromoUpload]);
  const [state, setState] = useMergeReducer(initialState);

  async function onDrop(acceptedFiles, rejectedFiles) {
    if (isDropDisabled) {
      setShouldNotify(true);
      return;
    }

    const [newState, dropErrors] = await handleFileSubmission(
      acceptedFiles,
      api,
      config,
      currentProperty,
      state.images,
      isPromoUpload,
      state.palette,
      rejectedFiles,
      user,
    );

    const shouldRedirect = !isDefaultUpload && !isPromoUpload;

    if (shouldRedirect) {
      history.push(UPLOAD_PATH);
    }

    setUploadErrors(dropErrors);
    setState({
      ...newState,
      isBulkEditFormOpen: isPromoUpload ? true : newState.isBulkEditFormOpen,
    });
  }

  const disabled = !isAuthenticated || (isAuthenticated && !user);

  const dropzoneConfig = useMemo(() => {
    const staticConfig = getDropzoneConfig(config, disabled);
    const eventListeners = {
      onClick(event) {
        event.preventDefault();
      },
      onDragEnter() {
        setState({ isDragActive: true });
      },
      onDragLeave() {
        setState({ isDragActive: false });
      },
      getFilesFromEvent(event) {
        return handleGetFilesFromEvent(event, config);
      },
    };
    return { ...staticConfig, ...eventListeners };
  }, [config, disabled, setState]);

  const { getRootProps, getInputProps, open } = useDropzone({ ...dropzoneConfig, onDrop });

  function cleanUploadState() {
    setUploadErrors([]);
    setState(initialState);
  }

  const contextValue = {
    ...state,
    isPromoUpload,
    open,
    cleanUploadState,
    setUploadState: setState,
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      {!!uploadErrors.length && currentRoute !== '/' && (
        <div className="dropzone__alert--container">
          {uploadErrors.map((error) => (
            <Alert
              type="error"
              icon={IconError}
              key={error.id}
              margin={{ bottom: 'md' }}
              className="dropzone__alert"
              onDismiss={() => setUploadErrors(uploadErrors.filter((err) => err.id !== error.id))}
            >
              {error.content}
            </Alert>
          ))}
        </div>
      )}

      {shouldNotify && (
        <Toast position="center" autoDismiss onDismiss={() => setShouldNotify(false)}>
          Image upload is not allowed in this page.
        </Toast>
      )}

      <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>
    </div>
  );
}

export { useDragAndDrop };
export default UploadProvider;
