import forOwn from 'lodash/forOwn';

/**
 * Builds a new image object to push to state.
 *
 * @param {} config // ! ...
 * @param {boolean} isPromoUpload A flag that indicates whether or not the current upload is for
 * promo images
 * @returns {import('../Types/d').Image}
 */
function getInitialImageState(config, isPromoUpload) {
  const metaMap = config.get('app.IMAGE_METADATA_MAP', {});
  const uploadTemplate = config.get(`app.FORMS.${isPromoUpload ? 'SIZED_' : ''}UPLOAD`, {});

  // * use metaMap because uploadTemplate is mutated after the fact with the data we need

  const initializeType = {
    colorPicker: '#FFFFFF',
    date: null,
    select: 'Photo',
    text: '',
    textarea: '',
    textArray: [],
  };

  const formData = isPromoUpload
    ? {}
    : { IMAUTOPUB: false, ONETIME_USE: false, RESIZE_ONLY: false, UNCREDITED: false };

  const invalidFields = {};

  forOwn(uploadTemplate, (_, key) => {
    formData[key] = initializeType[metaMap[key].input];
    invalidFields[key] = false;
  });

  const validation = {
    errorStatus: null,
    errorStatusText: null,
    invalidFields,
    isValid: true,
    status: null,
    uploadId: null,
  };

  if (isPromoUpload) validation.isDuplicate = false;

  return {
    formData,
    metaData: {},
    validation,
    uuid: null,
  };
}

export default getInitialImageState;
