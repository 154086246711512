function getDropzoneConfig(config, disabled) {
  const accept = ['jpeg', 'png', 'gif'].map((type) => `image/${type}`);


  return {
    accept,
    disabled,
    maxSize: 2e7, // 20 MB
    noClick: true,
    noKeyboard: true,
  };
}

export { getDropzoneConfig };
