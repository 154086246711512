import uuidV3 from 'uuid/v3';
import fileType from 'file-type/browser';

/**
 * Returns the `height` and `width` of the image corresponding with the input `src`.
 *
 * @param {string} src `<img />` src attribute
 * @returns {Promise<{ height: number, width: number}>}
 */
async function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    const imgEl = new Image();
    imgEl.onload = () => resolve({ height: imgEl.naturalHeight, width: imgEl.naturalWidth });
    imgEl.onerror = reject;
    imgEl.src = src;
  });
}

function getFileReader(uploadProps) {
  return async function readFile(file) {
    const typeDetails = await fileType.fromBlob(file);
    const mimeType = typeDetails.mime;
    const headerExt = mimeType.split('/')[1];

    // TODO: avoid mutating the file
    Object.assign(file, { mimeType });

    if (uploadProps.ACCEPT && uploadProps.ACCEPT.includes(headerExt)) {
      const imageUrl = URL.createObjectURL(file);
      const { height, width } = await addImageProcess(imageUrl);
      const uuid = await uuidV3(imageUrl, uuidV3.URL);

      Object.assign(file, {
        uuid,
        path: imageUrl,
        sizeKb: Math.round(file.size / 1000),
        sizeMb: Math.round((file.size / 1000 / 1000) * 10) / 10,
        width,
        height,
      });
    }

    return file;
  };
}

/**
 * Extract the files that the user uploaded from the event.
 *
 * @param {SyntheticEvent} event A React SyntheticEvent
 * @param {} config // ! ...
 * @returns {Promise<import('../../Types/d').MetaData>}
 */
async function handleGetFilesFromEvent(event, config) {
  const uploadProps = config.get('app.UPLOAD', {});

  const readFile = getFileReader(uploadProps);
  const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
  const readFilesTasks = Object.values(fileList).map(readFile);

  // this is passed to `react-dropzone` followed by `handleFileDrop`
  return Promise.all(readFilesTasks);
}

export default handleGetFilesFromEvent;
