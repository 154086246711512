import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import compact from 'lodash/compact';
import Token from '@screentone/core/Token';

import DynamicIcon from '../../../DynamicIcon';
import { ConfigContext } from '../../../../providers/ConfigContext';
import './ImageLabels.styles.css';

// TODO: move this change to backend
const COLOR_MAP = {
  red: 'lava',
  blue: 'ice',
  brown: 'tangerine',
  teal: 'emerald',
  violet: 'blurple',
  orange: 'sunshine',
};

function ImageLabels({ image, property }) {
  const { config } = useContext(ConfigContext);
  const imageData = image.raw || image;

  const labels = config.get('app.SEARCH_LABELS', []).filter((label) => {
    const content = Array.isArray(label.content)
      ? compact(label.content.map((labelName) => get(imageData, labelName)))[0]
      : label.content;

    if (['jpeg', 'png', 'gif'].includes(label.title.toLowerCase())) {
      return false;
    }

    if (content && label.keyword) {
      const keyword = label.keyword.replace('$Publication', `${property.toUpperCase()}`);

      if ((typeof content === 'string' ? content.split(',') : content).includes(keyword)) {
        return true;
      }

      // content is `imagemanager.cropsets.length` for crop label
    } else if (label.title === 'Cropped' && content) {
      return true;
    }

    return false;
  });

  // from src/routes/property/ImageDetails/ImageDetailsContext/index.jsx
  const meta = imageData.metadata || imageData.meta;
  let keywords =
    get(meta, 'iptc.Keywords') ||
    get(meta, 'iptc.Keyword') ||
    get(meta, 'keyword') ||
    get(meta, 'keywords') ||
    [];

  keywords = typeof keywords === 'string' ? keywords.split(',') : keywords;

  // TODO: handle promo label better
  // TODO: standardize labels
  // add promo label
  if (keywords.includes('IMSIZEDIMAGE')) {
    labels.push(
      config.get('app.MY_UPLOADS_LABELS').find((label) => label.keyword === 'IMSIZEDIMAGE'),
    );
  }

  return (
    <div className="grid-image-labels-container">
      {labels.map((label) => (
        <div key={label.title} className="grid-image-label" title={label.title}>
          <Token
            icon={DynamicIcon({ icon: label.icon })}
            color={COLOR_MAP[label.color]}
            className="grid-image-label-icon"
          />
        </div>
      ))}
    </div>
  );
}

ImageLabels.propTypes = {
  image: PropTypes.shape().isRequired,
  property: PropTypes.string.isRequired,
};

export default ImageLabels;
