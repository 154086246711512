import get from 'lodash/get';

export const actions = {
  ADD_IMAGE: 'image/add_image',
  SET_STATUS: 'image/set_status',
  SET_METADATA: 'image/set_metadata',
  SET_LOGS: 'image/set_logs',
  SET_CROPSETS: 'image/set_cropsets',
  CLEAR_CURRENT: 'image/clear_current',
  CLEAR_IMAGES: 'image/clear_images',
  SET_CURRENT: 'image/set_current',
  SET_SIZES: 'image/set_sizes',
  SET_CURRENT_CROPSET: 'image/set_current_cropset',
  CLEAR_LOGS: 'image/clear_logs',
};

export const initialState = {
  images: {},
  currentImage: null,
  status: null,
};

const getFirstSentence = (caption) => {
  const sentences = caption?.split('. ') || []; // Captions are not always required so fallback to empty array
  return sentences[0] !== caption ? `${sentences[0]}.` : sentences[0];
};

const processImage = (imageData) => {
  const image = { ...imageData };
  image.ContentType = image.ContentType || image.contenttype;

  if (image.contenttype) {
    delete image.contenttype;
  }

  image.firstSentence = getFirstSentence(image.caption);
  image.keywords =
    get(image, 'metadata.iptc.Keywords') ||
    get(image, 'metadata.iptc.Keyword') ||
    get(image, 'metadata.keyword') ||
    get(image, 'metadata.keywords') ||
    [];

  return image;
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATUS:
      return { ...state, status: { [action.imageId]: action.status } };
    case actions.SET_METADATA: {
      if (!state.currentImage) return state;
      const { id } = state.currentImage;
      const currentImage = {
        ...state.images[id],
        caption: action.metadata.caption,
        credit: action.metadata.credit,
        firstSentence: getFirstSentence(action.metadata.caption),
        logs: null,
      };
      return {
        ...state,
        currentImage,
        images: {
          ...state.images,
          [id]: currentImage,
        },
      };
    }
    case actions.SET_LOGS: {
      if (!state.currentImage) return state;
      const { id } = state.currentImage;

      let logs = [...action.logs];
      if (action.mergeLogs) {
        // merge new logs with prev state logs,
        // single log needs update
        logs = [...state.currentImage.logs.activity, ...logs].sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
        );
      }

      const currentImage = {
        ...state.currentImage,
        logs: {
          activity: logs,
          error: action.error,
        },
      };

      return {
        ...state,
        currentImage,
        images: {
          ...state.images,
          [id]: currentImage,
        },
      };
    }
    case actions.SET_CURRENT_CROPSET:
      if (!state.currentImage) return state;
      return {
        ...state,
        currentImage: { ...state.currentImage, currentCropsetId: action.cropsetId },
      };
    case actions.SET_CROPSETS: {
      if (!state.currentImage) return state;
      const { id } = state.currentImage;

      let cropsets = {};
      if (action.mergeCrops) {
        // merge new crop with prev state crops,
        // single crop needs update
        cropsets = { ...state.currentImage.crops.cropsets };
        cropsets[action.crop.id] = action.crop;
      } else {
        cropsets = { ...action.crops.cropsets };
      }

      const currentImage = {
        ...state.currentImage,
        crops: {
          cropsets,
          error: action.error,
        },
      };

      return {
        ...state,
        currentImage: {
          ...currentImage,
          currentCropsetId:
            state?.currentImage?.currentCropsetId || action?.crops?.currentCropsetId,
        },
        images: {
          ...state.images,
          [id]: currentImage,
        },
      };
    }
    case actions.SET_SIZES: {
      if (!state.currentImage) return state;
      const { id } = state.currentImage;
      const currentImage = {
        ...state.currentImage,
        sizes: action.sizes,
      };

      return {
        ...state,
        currentImage: { ...currentImage },
        images: {
          ...state.images,
          [id]: currentImage,
        },
      };
    }
    case actions.ADD_IMAGE:
      return {
        ...state,
        images: {
          ...state.images,
          [action.imageId]: processImage(action.image),
        },
      };
    case actions.SET_CURRENT:
      return {
        ...state,
        currentImage: { ...processImage(action.image), id: action.imageId },
      };
    case actions.CLEAR_CURRENT: {
      return { ...state, currentImage: null };
    }
    case actions.CLEAR_IMAGES: {
      const newImages = state.images;
      action.images.forEach((id) => delete newImages[id]);
      return { ...state, images: newImages };
    }
    case actions.CLEAR_LOGS: {
      if (!state.currentImage) return state;
      const { id } = state.currentImage;
      const currentImage = {
        ...state.currentImage,
      };

      if (action.cropId) {
        currentImage.logs.activity = currentImage.logs?.activity?.filter(
          (a) => a.item_id !== action.cropId,
        );
      } else {
        currentImage.logs = null;
      }

      return {
        ...state,
        currentImage,
        images: {
          ...state.images,
          [id]: currentImage,
        },
      };
    }
    default:
      return state;
  }
}
