import cropUtil from './cropUtil';

export default async function postCMS(images, configContext) {
  try {
    const cropsets = [];
    const toCrop = [];

    images.forEach(img => {
      const details = img.imageDetails;
      if (details?.imagemanager?.cropsets?.length) {
        cropsets.push(details.imagemanager.cropsets[0]);
      } else {
        toCrop.push(details);
      }
    });

    if (toCrop.length) {
      const defaultCrops = await Promise.all(toCrop.map(img =>
        cropUtil.getAutoCrop(img, configContext.config),
      ));

      const newCropsets = await Promise.all(defaultCrops.map(cropData =>
        configContext.api.post.crop(cropData.id, {}, JSON.stringify(cropData)),
      ));

      newCropsets.forEach(img => {
        cropsets.push(img.crops[0].cropset_id);
      });
    }

    const crops = await configContext.api.v2.get.setcrops({ ids: cropsets });
    (window.parent || window.opener).postMessage(JSON.stringify(crops.map((cropItem, i) =>
      ({ ...cropItem, ref: `${parseInt(sessionStorage.getItem('im.IFRAME_REF'), 10) + i}` }),
    )), '*');
  } catch (err) {
    console.error('ERROR postCMS', err);
  }
}
