import React, { useContext } from 'react';
import { Group, FormLabel, Switch, FormHelperText } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { ConfigContext } from '../../providers/ConfigContext';


export default function NewSearchOptions() {
  const { userAccess } = useAuth();
  const { searchOptions, setSearchOptions } = useContext(ConfigContext);
  return (
    <Group direction="column" gap="none" style={{ width: '240px' }}>
      {userAccess('enableNewCropSearch') && (
        <Group.Item>
          <FormLabel label="Use New Crop Search" labelPosition="right">
            <Switch
              checked={searchOptions?.crop === 'new'}
              onChange={() =>
                setSearchOptions({ ...searchOptions, crop: searchOptions?.crop === 'new' ? 'old' : 'new' })}
            />
          </FormLabel>
        </Group.Item>
      )}
      {userAccess('enableNewUploadSearch') && (
      <Group.Item>
        <FormLabel label="Use New Upload Search" labelPosition="right">
          <Switch
            checked={searchOptions?.upload === 'new'}
            onChange={() =>
              setSearchOptions({ ...searchOptions, upload: searchOptions?.upload === 'new' ? 'old' : 'new' })}
          />
        </FormLabel>
      </Group.Item>
      )}
      <Group.Item>
        <FormHelperText margin={{ left: 'xl' }}>
          Temporary options to revert to the original searches, while development is ongoing.
        </FormHelperText>
      </Group.Item>
    </Group>
  );
}
