import get from 'lodash/get';
import set from 'lodash/fp/set';

/* eslint-disable import/prefer-default-export */

/**
 * ! DOCS
 *
 * updated config from server and format for use
 *
 * @param {*} baseConfig
 * @param {*} property
 * @param {*} user
 */
const buildNewConfig = (config, property = null, user = null) => {
  // TODO: deep freeze config
  const acceptedUploadImageTypes = ['jpeg', 'png', 'gif'];

  // * set function from lodash/fp does not mutate `config`
  const updatedConfig = property && user
    ? Object.freeze(set('app.UPLOAD.ACCEPT', acceptedUploadImageTypes, config))
    : Object.freeze(config);

  return ({
    ...updatedConfig,
    get: (path, defaultValue = null) => get(updatedConfig, path, defaultValue),
  });
};

export { buildNewConfig };
