import React, { createContext, useReducer } from 'react';
import { actions, initialState, reducer } from './select.reducer';

const SelectContext = createContext({
  state: { }, dispatch: () => { }, actions: { },
});

const SelectContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <SelectContext.Provider value={{ state, dispatch, actions }}>
      { children }
    </SelectContext.Provider>
  );
};

export const useSelectContext = () => React.useContext(SelectContext);

export default SelectContextProvider;
