import React, { useState } from 'react';
import PropTypes from 'prop-types';

// TODO: image request in "My Uploads" brings in images in a different shape than
// request in Crop-- - unify shapes
const PropertyContext = React.createContext();

/**
 * Centralized state for `Crop/`. This component grabs the image that the user chose to crop,
 * `user`, and other data related to the current crop, and holds it on state for use throughout
 * `Crop/`.
 */
function PropertyContextProvider({ children }) {
  const [state, setState] = useState({ images: [] });

  return (
    <PropertyContext.Provider
      value={{
        ...state,
        setPropertyContextState: (newState) => setState({ ...state, ...newState }),
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
}

export { PropertyContext };
export default PropertyContextProvider;

/* eslint-disable react/forbid-prop-types */

PropertyContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
