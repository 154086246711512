import { useReducer } from 'react';

function mergeReducer(oldState, newState) {
  return { ...oldState, ...newState };
}

function useMergeReducer(initialState) {
  return useReducer(mergeReducer, initialState);
}

export { useMergeReducer };
