import React from 'react';
import ExifReader from 'exifreader';

/**
 * Reads the `file` uploaded by the user.
 *
 * @param {import('../../Types/d').MetaData} file An image submitted by the user
 * @returns {Promise<import('../../Types/d').MetaData>}
 */
async function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
  });
}
/**
 * Check if `file` has been downloaded from Image Manager and is attempting to be reuploaded. Will
 * not identify images that are already in the system, but have not been downloaded from Image
 * Manager.
 *
 * @param {import('../../Types/d').MetaData} file An image submitted by the user
 * @param {} api // ! ...
 * @returns {Promise<import('../../Types/d').MetaData>}
 */
async function alreadyInSystem(file, api) {
  const fileBinary = await readFileAsync(file);
  const arr = new Uint8Array(fileBinary).subarray(0, 4);
  const pattern = [0xff, 0xd8];

  // check to see if file is actually in jpg format
  for (let i = 0; i < 2; i++) {
    if (arr[i] !== pattern[i]) {
      // eslint-disable-next-line no-param-reassign
      file.isCorrupt = true;
      return file;
    }
  }

  const tags = await ExifReader.load(fileBinary);
  if (tags.UniqueID) {
    await api.get
      .imageDetails(tags.UniqueID.description)
      .then((res) => {
        // eslint-disable-next-line no-param-reassign
        file.alreadyInSystem = !!res.id;
        // eslint-disable-next-line no-param-reassign
        file.UniqueID = tags.UniqueID.description;
      })
      // error might be in system in a different env
      .catch(() => {
        // eslint-disable-next-line no-param-reassign
        file.alreadyInSystem = false;
      });
  }
  return file;
}

/**
 * Iterate through the files that have been sucessfully submitted by the user, `acceptedFiles` and
 * create an array of error messages for each failing file. If a file produces an error, filter it
 * out. Return the filtered files array and the errors.
 *
 * @param {string[]} acceptedFileTypesArray An array of strings representing the valid file types
 * for the current publication
 * @param {import('../../Types/d').MetaData[]} acceptedFiles An array of files the user submitted
 * that react-dropzone has accepted
 * @param {} api // ! ...
 * @param {} config // ! ...
 * @param {boolean} isPromoUpload A flag that indicates whether or not the current upload is for
 * promo images
 * @returns {[import('../../Types/d').MetaData[], import('./d').ErrorMessage[]]}
 */
async function handleAcceptedFilesErrors(
  acceptedFileTypesArray,
  acceptedFiles,
  api,
  config,
  currentProperty,
  isPromoUpload,
) {
  const newErrors = [];
  const promises = await acceptedFiles.map((file) => {
    if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
      return alreadyInSystem(file, api);
    }
    return file;
  });
  await Promise.all(promises).catch((err) => console.error('ERROR', err));

  /* eslint-disable array-callback-return, consistent-return */
  const filteredAcceptedFiles = acceptedFiles.reduce((accepted, image) => {
    // image is corrupt (file type is jpg, but is actually not jpg)
    if (image.isCorrupt) {
      newErrors.push({
        id: image.name,
        content: `${image.name} has been corrupted and was not able to be uploaded.`,
        type: 'negative',
      });

      // file format provided is not valid (e.g. pdf)
    } else if (!acceptedFileTypesArray.includes(image.mimeType) || image.mimeType !== image.type) {
      newErrors.push({
        id: image.name,
        content: `${image.name} is an invalid file format. Please upload a ${acceptedFileTypesArray
          .join(', ')
          .toUpperCase()
          .replace(/, ([^,]*)$/, ' or $1')} image.`,
        type: 'negative',
      });

      // image is png or gif and larger than 6mb, but less than 20mb
    } else if (
      (image.type === 'image/png' || image.type === 'image/gif') &&
      Number(image.size) > Number(6e6)
    ) {
      newErrors.push({
        id: image.name,
        content: `${image.name} is larger than 6MB. ${image.type
          .replace('image/', '')
          .toUpperCase()} images cannot be larger than 6MB. Please resize and re-upload the image.`,
        type: 'negative',
      });

      // image is taken from image manager and already in system
    } else if (image.alreadyInSystem) {
      const linkPath = config.get('app.INTERNAL_IMAGE_URL').split('/')[2];
      const link = React.createElement(
        'a',
        {
          href: `//${linkPath}/${currentProperty}/image/${image.UniqueID}`,
          rel: 'noopener noreferrer',
          target: '_blank',
        },
        image.UniqueID,
      );
      newErrors.push({
        id: image.name,
        content: <span>Your image is already in the system (ID : {link})</span>,
        type: 'negative',
      });

      // Promo Upload: images is larger than 9mb
    } else if (isPromoUpload && Number(image.size) > Number(9e6)) {
      newErrors.push({
        id: image.name,
        content: `${image.name} is larger than 9MB. Promo upload JPEG images cannot be larger than 9MB. Please resize and re-upload the image.`,
        type: 'negative',
      });

      // the dimensions of the image are too big
    } else if (image.height * image.width >= config.get('app.UPLOAD.RENDERING_RESTRICTIONS.MAX_PIXEL_LIMIT', 100000000)) {
      const largestTotalPixels = Number(config.get('app.UPLOAD.RENDERING_RESTRICTIONS.MAX_PIXEL_LIMIT', 100000000)).toLocaleString();
      const recommendedTotalPixels = Number(config.get('app.UPLOAD.RENDERING_RESTRICTIONS.RECOMMENDED_PIXEL_LIMIT', 5000000)).toLocaleString();

      const errorText = `${image.name} has larger dimensions than are permitted. Please pre-crop and/or resize the image.`;
      const performanceText = `Due to performance issues, rendering images with a total number of pixels greater than ${largestTotalPixels} is prohibited.`;
      const maxLimitText = `This image has a total number of pixels of ${(image.width * image.height).toLocaleString()} (width * height = total pixels). Please crop the white space of the image and/or resize the image and re-upload.`;
      const suggestedLimitText = `It is recommended to upload images with a total number of pixels less than ${recommendedTotalPixels} for quickest load times.`;

      newErrors.push({
        id: image.name,
        content: `${errorText} ${performanceText} ${maxLimitText} ${suggestedLimitText}`,
        type: 'negative',
      });

      // there were no errors
    } else {
      accepted.push(image);
    }

    return accepted;
  }, []);

  return [filteredAcceptedFiles, newErrors];
}

/**
 * Iterate through the files that have been submitted by the user and rejected, `rejectedFiles` and
 * create an array of error messages for each failing file. Return the errors.
 *
 * @param {string[]} acceptedFileTypesArray An array of strings representing the valid file types
 * for the current publication
 * @param {boolean} isPromoUpload A flag that indicates whether or not the current upload is for
 * promo images
 * @param {import('../../Types/d').MetaData[]} rejectedFiles An array of files the user submitted
 * that react-dropzone has rejected
 * @returns {import('./d').ErrorMessage[]}
 */
function handleRejectedFilesErrors(acceptedFileTypesArray, isPromoUpload, rejectedFiles) {
  const newErrors = [];

  rejectedFiles.forEach((image) => {
    // jpeg image is larger than 9mb on promo upload
    if (image.file.type === 'image/jpeg' && Number(image.file.size) > Number(2e7) && isPromoUpload) {
      newErrors.push({
        id: image.file.name,
        content: `${image.file.name} is larger than 9MB. Promo upload JPEG images cannot be larger than 9MB. Please resize and re-upload the image.`,
        type: 'negative',
      });

      // jpeg image is larger than 20mb
    } else if (image.file.type === 'image/jpeg' && Number(image.file.size) > Number(2e7)) {
      newErrors.push({
        id: image.file.name,
        content: `${image.file.name} is larger than 20MB. ${image.file.type
          .replace('image/', '')
          .toUpperCase()} images cannot be larger than 20MB. Please resize and re-upload the image.`,
        type: 'negative',
      });

      // image is png or gif and larger than 20mb
    } else if (
      (image.file.type === 'image/png' || image.file.type === 'image/gif') &&
      Number(image.file.size) > Number(2e7)
    ) {
      newErrors.push({
        id: image.file.name,
        content: `${image.file.name} is larger than 6MB. ${image.file.type
          .replace('image/', '')
          .toUpperCase()} images cannot be larger than 6MB. Please resize and re-upload the image.`,
        type: 'negative',
      });

      // file type is not accepted
    } else if (
      !acceptedFileTypesArray.includes(image.file.mimeType)
      || image.file.mimeType !== image.file.type
    ) {
      newErrors.push({
        id: image.file.name,
        content: `${image.file.name} is an invalid file format. Please upload a ${acceptedFileTypesArray
          .join(', ')
          .toUpperCase()
          .replace(/, ([^,]*)$/, ' or $1')} image.`,
        type: 'negative',
      });

      // catch all
    } else {
      newErrors.push({
        id: image.file.name,
        content: `Unknown Error uploading ${image.file.name}.`,
        type: 'negative',
      });
    }
  });
  return newErrors;
}

export { handleAcceptedFilesErrors, handleRejectedFilesErrors };
