import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Loader, Typography, IconPlusCircle, IconCrop } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';

function Actions(props) {
  const {
    sendArray,
    isPosting,
    isLoading,
    selectedImages,
    postData,
  } = props;

  const { currentProperty } = useAuth();

  const allResizeOnly = selectedImages.every(img => {
    if (img.imageDetails) {
      return !img?.imageDetails?.properties?.canCrop;
    }
    return false;
  });

  const uncroppedLength = selectedImages.reduce((sum, img) => {
    if (img.imageDetails) {
      return sum + (img?.imageDetails?.imagemanager?.cropsets?.length ? 0 : 1);
    }
    return sum;
  }, 0);

  const actionUrl = () => {
    const croppableIds = selectedImages.filter(img => img?.imageDetails?.properties?.canCrop)
      .map(img => img.id);

    if (croppableIds.length === 1) {
      return `/${currentProperty}/image/${croppableIds[0]}/crop`;
    }

    if (croppableIds.length) {
      return `/${currentProperty}/crop/batch?ids=${croppableIds.join(',')}`;
    }

    return '#';
  };

  return (
    <React.Fragment>
      {sendArray &&
        <Button
          key="lightbox-add"
          primary
          fullWidth
          disabled={isLoading || isPosting || !selectedImages.length}
          icon={isPosting ? null : IconPlusCircle}
          onClick={postData}
        >
          {
            isPosting ?
              <Loader size="md" /> :
              'Add to Story'
          }
        </Button>
      }
      {sendArray && !!uncroppedLength && <Typography size="sm" color="asphalt" margin={{ top: 'sm' }}>When adding to story, {uncroppedLength} uncropped image(s) will be auto-cropped and published.</Typography>}
      <Button
        icon={IconCrop}
        key="lightbox-crop"
        to={() => actionUrl()}
        disabled={isLoading || isPosting || allResizeOnly}
        fullWidth
        secondary
        componentEl={Link}
        margin={{ top: 'sm' }}
      >
        Crop Images
      </Button>
      {allResizeOnly && !!selectedImages.length && <Typography size="sm" color="asphalt" margin={{ top: 'sm' }}>You can not crop resize-only images. Add other images to crop.</Typography>}
    </React.Fragment>
  );
}

Actions.propTypes = {
  sendArray: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool.isRequired,
  selectedImages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  postData: PropTypes.func.isRequired,
};

export default Actions;
