import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Typography, Sidebar, Button, IconCheckCircle, IconMinusCircle, Loader, ThemeContext } from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import { isFrame } from '../../../helpers';
import { useSelectContext } from '../../../providers/SelectContext';
import { ConfigContext } from '../../../providers/ConfigContext';
import Actions from './Actions.component';
import postCMS from '../../../helpers/postCMS';
import ImageLabels from '../../Gallery/GalleryImage/ImageLabels';
import usePrevious from '../../../helpers/hooks/usePrevious';
import { useImageContext } from '../../../providers/ImageContext';

function Lightbox(props) {
  const {
    state: { images: loadedImages },
    actions: actionsImage,
    dispatch: dispatchImage,
  } = useImageContext();
  const { state: selectState, actions, dispatch } = useSelectContext();
  const { alert, selected } = selectState;
  const [isPosting, setIsPosting] = useState(false);
  const selectedImages = Object.values(selected || {});
  const { currentProperty } = useAuth();
  const { modeDisplayed } = useContext(ThemeContext);
  const sendArray = isFrame() && ['np-gws', 'np-gallery'].includes(sessionStorage.getItem('im.IFRAME_SOURCE'));
  const configContext = useContext(ConfigContext);
  const prevProperty = usePrevious(currentProperty);

  const getImageDetails = useCallback(
    async (id) => {
      dispatch({ type: actions.GET_DETAILS, id });
      const image = loadedImages[id] || (await configContext.api.get.imageDetails(id));
      dispatch({ type: actions.SET_DETAILS, id, image });
      if (!loadedImages[id]) {
        dispatchImage({ type: actionsImage.ADD_IMAGE, imageId: id, image });
      }
    },
    [
      configContext,
      actions.SET_DETAILS,
      actions.GET_DETAILS,
      actionsImage.ADD_IMAGE,
      dispatch,
      dispatchImage,
      loadedImages,
    ],
  );

  useEffect(() => {
    if (currentProperty !== prevProperty) {
      dispatch({ type: actions.CLEAR_IMAGES });
    }
  }, [currentProperty, prevProperty, dispatch, actions.CLEAR_IMAGES]);

  useEffect(() => {
    Object.values(selected || {}).forEach((img) => {
      if (!img.imageDetails && !img.isLoading) {
        getImageDetails(img.id);
      }
    });
  }, [selected, getImageDetails]);

  const postData = async () => {
    setIsPosting(true);
    await postCMS(Object.values(selected), configContext);
    setIsPosting(false);
  };

  const lightboxActions = configContext && (
    <Actions
      sendArray={sendArray}
      isPosting={isPosting}
      isLoading={Object.values(selected || {}).some((img) => img.isLoading)}
      selectedImages={selectedImages}
      postData={() => postData()}
    />
  );

  return (
    <>
      {alert && (
        <Alert
          floating
          autoDismiss
          autoDismissTime={4}
          icon={IconCheckCircle}
          type="warning"
          margin={{ top: 'smd' }}
          onDismiss={() => dispatch({ type: actions.CLEAR_ALERT })}
        >
          {alert}
        </Alert>
      )}
      <Sidebar {...props} border position="right" top={isFrame() ? '40px' : '100px'}>
        <Sidebar.Section>
          <div className="lightbox">
            <Typography variant="header4">Lightbox</Typography>
            {selectedImages.length ? (
              <Button
                tertiary
                icon={IconMinusCircle}
                className="lightbox--remove"
                onClick={() => dispatch({ type: actions.CLEAR_IMAGES })}
              >
                Remove all from list
              </Button>
            ) : (
              <Typography color="asphalt">No images selected yet.</Typography>
            )}
            {[...selectedImages.reverse()].map((image) => {
              const buttonColor = modeDisplayed === 'dark' ? 'gray' : 'white';

              return (
                <div
                  key={image.id}
                  className="image--container"
                  onClick={() => dispatch({ type: actions.IMAGE_SELECTED, image })}
                >
                  <div className="image--mask">
                    <Typography className="remove--label" componentEl="p" color={buttonColor}>
                      <IconMinusCircle color={buttonColor} size="md" />
                      Remove
                    </Typography>
                  </div>
                  {image.isLoading && <Loader className="loading--label" size="md" />}
                  <ImageLabels image={image.imageDetails || image} property={currentProperty} />
                  <img className="image" alt={image.id} src={image.src} />
                </div>
              );
            })}
          </div>
        </Sidebar.Section>
        <Sidebar.Footer style={{ padding: 16 }}>{lightboxActions}</Sidebar.Footer>
      </Sidebar>
    </>
  );
}

export default Lightbox;
