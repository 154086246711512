import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Token } from '@screentone/core';
import constantsUtil from '../../helpers/constantsUtil';

import './Version.styles.css';

function Version({ apiVersion, toolVersion }) {
  const { TOOL_ID, API_ID } = constantsUtil.constantList;

  return (
    <React.Fragment>
      {' - '}Client:{' '}
      <Link
        to={{ pathname: '/changelog', state: { logType: TOOL_ID } }}
        className="changelog-version-item"
      >
        <Token color="blurple" data-cy="tool-version">
          {toolVersion}
        </Token>
      </Link>
      {apiVersion && (
        <React.Fragment>
          {' - '}Image API:{' '}
          <Link
            to={{ pathname: '/changelog', state: { logType: API_ID } }}
            className="changelog-version-item"
          >
            <Token color="blurple" data-cy="api-version">
              {apiVersion}
            </Token>
          </Link>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

Version.propTypes = {
  apiVersion: PropTypes.string,
  toolVersion: PropTypes.string,
};

Version.defaultProps = {
  apiVersion: '0.0.0',
  toolVersion: '0.0.0',
};

export default Version;
