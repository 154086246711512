import findKey from 'lodash/findKey';
import forOwn from 'lodash/forOwn';

import constantsUtil from '../../../../../helpers/constantsUtil';


/**
 * Given an object, `imageCrops`, which contains all crops for this set, build an unsorted array of
 * metadata for each size crop using the global sizes, `tool.SIZES`.
 *
 * @param {Object<string, any>} imageCrops - Crops selected by user on context (`image.crops`)
 * @param {} sizes // ! ...
 * @returns {Object<string, any>[]}
 */
export const formatCrops = (crops, sizes) => {
  const toSort = [];

  if (crops) {
    let i = 0;
    forOwn(crops, ((crop, key) => {
      const matchedSize = findKey(sizes, item => item.name === key);

      // key is a standard size
      if (matchedSize) {
        toSort.push({
          key,
          height: sizes[matchedSize].height,
          name: sizes[matchedSize].displayName,
          originalIdx: i,
          savedSize: true,
          seed: sizes[matchedSize].seed,
          softcrop: sizes[matchedSize].softcrop,
          width: sizes[matchedSize].width,
        });

      // key is a custom size
      } else {
        toSort.push({
          key,
          height: crop.height,
          name: crop.displayName,
          originalIdx: i,
          savedSize: true,
          seed: false,
          softcrop: false,
          width: crop.width,
        });
      }
      i++;
    }));
  }
  return toSort;
};

/**
 * If `formData` contains `altCaption`, remove all new line characters and carriage
 * return characters, and cut off any characters that exceed the limit of 254.
 *
 * @param {import('./d').FormData} formData An object containing data extracted from the form on
 * `PublishTab`
 * @returns {import('./d').FormData}
 */
export const cleanAltCaption = (formData) => {
  if (formData && formData.altCaption) {
    const num = formData.altCaption.length < 255 ? 0 : formData.altCaption.length - 254;
    const regex = new RegExp(`.{${num}}$`);

    return ({
      ...formData,
      altCaption: formData.altCaption.replace(constantsUtil.regex.returnAndNewLine, ' ').replace(regex, ''),
    });
  }
  return formData;
};

/**
 * Extract data from the form on `PublishTab` and `BatchMetaForm` into an object, add some relevant
 * data and return that object.
 *
 * @param {HTMLFormElement} form HTML form
 * @param {import('../Types/d').Crop[]} crops An array of data for each crop
 * @returns {import('./d').FormData}
 */
export const getFormData = (form, crops) => {
  const formElem = new FormData(form);
  const formData = {};

  /* eslint-disable-next-line no-restricted-syntax, no-unused-vars */
  for (const [key, value] of formElem.entries()) {
    formData[key] = value;
  }

  // `BatchMetaForm` will not provide `crops` to avoid this data
  if (crops) {
    formData.isCustom = !!Object.keys(crops)[0].match(/custom_/);
    formData.isReCrop = !!formData.cropId;
  }

  return cleanAltCaption(formData);
};


export { handleBatchPublish } from './batchSubmissionUtils';
export { handlePublishCrop } from './handleSubmit';
export { getInitialSlug, isSlugValid, serializeSlug, validateSlug } from './slugUtils';
export { handleNewCrop, handleRecrop } from './submissionUtils';
