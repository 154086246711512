import React, { useCallback, useEffect, useState } from 'react';
import { Subnav, Switch, FormLabel, Sidebar, Group, Token, Button, Dropdown, IconMessageDots, List, IconGear, useSidebarState } from '@screentone/core';
import { useHistory, Link } from 'react-router-dom';
import { useAuth } from '@screentone/addon-auth-wrapper';

import { useSelectContext } from '../../providers/SelectContext';
import Lightbox from './Lightbox/Lightbox.component';
import { isFrame } from '../../helpers';
import NewSearchOptions from '../NewSearchOptions';
import './PageWrapper.styles.css';

const PATHS = {
  MAIN: '',
  CROPS: 'crop-logs',
  UPLOADS: 'upload-logs',
  MY_UPLOADS: 'my-uploads',
};

export default function PageWrapper(props) {
  const history = useHistory();
  const { app, currentProperty, userAccess } = useAuth();
  const { state, actions, dispatch } = useSelectContext();
  const headless = isFrame();

  const {
    openSidebar,
    closeSidebar,
    sidebarProps,
    pageProps,
  } = useSidebarState({
    type: 'slide',
    position: 'right',
    status: 'closed',
  });

  const getCurrentPath = useCallback(
    () => history.location.pathname.split('/')[2] || '',
    [history.location.pathname],
  );

  const [currentPath, setCurrentPath] = useState(getCurrentPath());
  const isImageView = Object.values(PATHS).includes(currentPath);

  useEffect(() => {
    if (state.isOpen && isImageView) {
      openSidebar();
    } else {
      closeSidebar();
    }
  }, [state.isOpen, openSidebar, closeSidebar, isImageView]);

  useEffect(() => {
    setCurrentPath(getCurrentPath());
  }, [history.location.pathname, getCurrentPath]);

  if (!currentProperty) {
    return props.children;
  }

  return (
    <React.Fragment>
      <Subnav className="subnav" align="space-between" margin={{ bottom: 'none' }}>
        <Group>
          <Subnav.Item
            active={currentPath === PATHS.MAIN || currentPath === currentProperty}
            componentEl={Link}
            to={`/${currentProperty}/${PATHS.MAIN}`}
            data-cy="all-images-tab"
          >
            All Images
          </Subnav.Item>
          <Subnav.Item
            active={currentPath === PATHS.CROPS}
            componentEl={Link}
            to={`/${currentProperty}/${PATHS.CROPS}`}
            data-cy="crops-tab"
          >
            Cropped
          </Subnav.Item>
          <Subnav.Item
            active={currentPath === PATHS.UPLOADS}
            componentEl={Link}
            to={`/${currentProperty}/${PATHS.UPLOADS}`}
            data-cy="uploads-tab"
          >
            Uploads
          </Subnav.Item>
          <Subnav.Item
            active={currentPath === PATHS.MY_UPLOADS}
            componentEl={Link}
            to={`/${currentProperty}/${PATHS.MY_UPLOADS}`}
            data-cy="my-uploads-tab"
          >
            My Uploads
          </Subnav.Item>
        </Group>

        <Group gap="sm">
          {headless && !['prod', 'production'].includes((app.env || '').toLowerCase()) && (
          <Token>{app.env.toUpperCase()}</Token>
          )}
          {headless && (
            <Dropdown trigger={<IconMessageDots />} position="right" padding={{ all: 'none' }}>
              <List margin={{ vertical: 'smd', horizontal: 'none' }}>
                {app.email && (
                <List.Item margin={{ vertical: 'xs', horizontal: 'smd' }}>
                  <Button fullWidth tertiary componentEl="a" target="_blank" href={`mailto:${app.email}`} rel="noreferrer">Email Us</Button>
                </List.Item>
              )}
                {app.slack && (
                <List.Item margin={{ vertical: 'xs', horizontal: 'smd' }}>
                  <Button fullWidth tertiary componentEl="a" target="_blank" href={app.slack.url} rel="noreferrer">#{app.slack.name} in Slack</Button>
                </List.Item>
              )}
                {app.feedbackUrl && (
                <List.Item margin={{ vertical: 'xs', horizontal: 'smd' }}>
                  <Button fullWidth tertiary componentEl="a" target="_blank" href={app.feedbackUrl} rel="noreferrer">Report Bug / Send Feedback</Button>
                </List.Item>
              )}
              </List>
            </Dropdown>
          )}
          {headless && (userAccess('enableNewCropSearch') || userAccess('enableNewUploadSearch')) && (
            <Dropdown trigger={<IconGear />} position="right" padding={{ all: 'none' }}>
              <List
                margin={{ all: 'none' }}
                padding={{ top: 'md', bottom: 'xs', horizontal: 'md' }}
                style={{ width: '260px' }}
              >
                <NewSearchOptions />
              </List>
            </Dropdown>
          )}
          <FormLabel label="Lightbox" labelPosition="right" margin={{ vertical: 'none', horizontal: 'sm' }}>
            <Switch
              checked={state.isOpen || false}
              disabled={!isImageView}
              onChange={(e) => {
                  dispatch({ type: actions.TOGGLE_SELECT });
                }}
            />
          </FormLabel>
        </Group>
      </Subnav>
      <Lightbox {...sidebarProps} />
      <Sidebar.Page {...pageProps} position="right">
        <div className="pagewrapper-container">
          {props.children}
        </div>
      </Sidebar.Page>
    </React.Fragment>
  );
}
