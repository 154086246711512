import values from 'lodash/values';
import Vibrant from 'node-vibrant';


/**
 * Given the url of the image that is to be cropped, generate a color palette
 * containing various shades from the image, then return a promise that
 * resolves to the palette array.
 *
 * @param {string} imgUrl A string representing the src attribute of an image
 * @returns {Promise<import('../Types/d').Palette>}
 */
export const getPalette = imgUrl =>
  Vibrant.from(imgUrl)
    .getPalette()
    .then((palette) => {
      const defaults = [
        {
          hex: '#FFFFFF',
          title: 'white',
        },
        {
          hex: '#000000',
          title: 'black',
        },
      ];

      // array of color data in palette
      return (
        values(palette)
          .sort((a, b) => b.population - a.population)
          .concat(defaults)
      );
    })
    .catch((err) => {
      console.error('COLOR PICKER ERROR', err);
      const defaults = [
        {
          hex: '#FFFFFF',
          title: 'white',
        },
        {
          hex: '#000000',
          title: 'black',
        },
      ];
      return defaults;
    });

export default getPalette;
